
import React, { useEffect } from "react";
import { useNetworkStatus } from "../components/NetworkContext";
import { toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css"; 

const GlobalMessage = () => {
  const { networkErrorMessage } = useNetworkStatus(); 

  useEffect(() => {
    if (networkErrorMessage) {
      toast.error(networkErrorMessage, {
        position: "top-center", 
        autoClose: 15000,
        hideProgressBar: true, 
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true, 
      });
    }
  }, [networkErrorMessage]);

  return null; 
};

export default GlobalMessage;
