import React, { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./Admin.css";
function BlockedUsers() {
  const [user, setUser] = useState([]);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const navigate = useNavigate();
  const loginUser = useSelector((state) => state.user.value);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchError, setSearchError] = useState("");
  const [showValidationMessage, setShowValidationMessage] = useState(false);

  const fetchUsers = () => {
    setIsLoading(true);
    AdminService.getAllUser(loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        if (data?.user?.user) {
          const sortedUsers = data.user.user.sort(
            (a, b) => b.userid - a.userid
          );
          setUser(sortedUsers);
          setFilteredUsers(sortedUsers); 
        } else {
          setUser([]);
          setFilteredUsers([]);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setUser([]);
        setFilteredUsers([]);
        setIsLoading(false);
        toast.error("Failed to fetch users.");
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const unblockUser = (userid) => {
    const confirmUnblock = window.confirm(
      "Are you sure you want to unblock this user?"
    );
    if (confirmUnblock) {
      toast.dismiss();
      setIsLoading(true);
      const updatedRole = { isactive: 1};
      AdminService.updateUserRole(updatedRole, userid, loginUser.token)
        .then((response) => response.json())
        .then((data) => {
          const updatedUser = user.find((user) => user.userid === userid);
          updatedUser.isactive= 1;
          setIsDataUpdated(!isDataUpdated);
          toast.success("User unblocked successfully!");
          setIsLoading(false);
        })
        .catch(() => {
          toast.error("Failed to unblock user.");
          setIsLoading(false);
        });
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleSearch = () => {
    if (!searchValue) {
      setShowValidationMessage(true);
      setSearchError("Please enter a valid name or email.");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[a-zA-Z ]+$/;
    if (nameRegex.test(searchValue)) {
      setSearchError("");
    } else if (!emailRegex.test(searchValue)) {
      setShowValidationMessage(true);
      setSearchError("Please enter a valid email.");
      return;
    }
    setSearchError("");
    const searchResults = user.filter(
      (r) =>
        (r.isactive === 1 || r.isactive === 0) &&
        (r.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          r.email.toLowerCase().includes(searchValue.toLowerCase()))
    );
    setFilteredUsers(searchResults);
  };

  const handleCancelSearch = () => {
    setSearchValue("");
    setIsLoading(false);
    fetchUsers();

  };

  return (
    <div className="container">
      <ToastContainer position="top-center" autoClose={2000}   className="responsive-toast"/>

      <div className="form-group row d-flex align-items-center justify-content-md-between justify-content-lg-between justify-content-center mb-5">
        <div className="col-lg-2 col-2 col-md-2 col-sm-2">
          <button
            className="btn btn-md bk-adm back-button"
            onClick={() => navigate("/user")}
          >
            Back
          </button>
        </div>
        <div className="col-10 col-sm-10 col-lg-7 col-md-6 text-center mb-sm-0 justify-content-center align-items-center">
          <h3 className="text-center">Blocked Users</h3>
        </div>

        <div className="col-8 col-sm-8 col-lg-3 col-md-4 justify-content-lg-end d-flex position-relative-block">
          <div>
            <input
              type="text"
              className={`form-control ${searchError ? "error-border" : ""}`}
              placeholder="name or email"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                if (searchError) {
                  setSearchError(""); 
                }
              }}
            />
           {searchValue && (
    <span
      className={`clear-input-icon-block ${searchError ? "move-icon-block" : ""}`}
      onClick={handleCancelSearch}
    >
      <i className="fa fa-times" />
    </span>
  )}
            {searchError && (
              <small className="text-danger">{searchError}</small>
            )}
          </div>

          <div>
            <button className="btn btn-green ms-2 mt-1" onClick={handleSearch}>
              Search
            </button>
          </div>
        </div>
      </div>

      {isLoading ? (
           <div className="text-center">
           <span> Loading</span>{" "}
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
     
      </div>
      ) : filteredUsers?.some((o) => o.isactive === 0) ? (
        <div className="table-responsive mb-5 mt-5">
          <table className="table table-bordered text-center">
            <thead>
              <tr className="text-center">
                <th>S.no</th>
                <th>Name</th>
                <th>Education</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers?.filter((o) => o.isactive === 0)
                .map((ob, index) => (
                  <tr key={index}>
                    <td>{indexOfFirstItem + index + 1}</td>
                    <td>{ob.name}</td>
                    <td>{ob.education}</td>
                    <td>{ob.contact}</td>
                    <td>{ob.email}</td>
                    <td>{ob.role}</td>
                    <td>
                      <div className="d-flex justify-content-center">
                        {ob.isactive === 0 && (
                          <button
                            className="btn btn-md btn-success me-2"
                            style={{ borderRadius: "10px" }}
                            onClick={() => unblockUser(ob.userid)}
                          >
                            Unblock
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-muted mt-5">No records found for blocked users.</p>
      )}
    </div>
  );
}

export default BlockedUsers;
