import React, { useState, useEffect, useRef, useId } from "react";
import Sidebar from "./Sidebar";
import "./Other.css";
import { useParams } from "react-router-dom";
import ResumeService, { urls } from "../../../services/ResumeService";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SOthers() {
  const { userId } = useParams();
  const logindata = useSelector((state) => state.user.value);

  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [otherList, setOtherList] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const [isFormSubmitted, setIsFormSubmitted] = useState([]);
  const lastRecordRef = useRef(null);

  const [otherId, setOtherId] = useState(true);
  const [formWidth, setFormWidth] = useState("35%");
  const [showForm, setShowForm] = useState(true);
  const [infoform, setInfoForm] = useState(false);
  const jobTitleRef = useRef("");
  const careerRef = useRef("");
  const languageKnownRef = useRef("");
  const skillsRef = useRef("");
  const maritalStatusRef = useRef("");
  const nationalityRef = useRef("");
  const interestRef = useRef("");
  const [formData, setFormData] = useState({
    job_title: "",
    objective: "",
    language_known: "",
    skills: "",
    interest: "-",
    marital_status: "-",
    nationality: "India",
  });
  const fetchOtherList = async () => {
    if (!logindata.userId) {
      return;
    }

    try {
      setLoading(true);
      const fetchUrl = `${urls.Show_Info}/${logindata.userId}`;
      const response = await ResumeService.GetApiCallWithToken(
        fetchUrl,
        logindata.token
      );
      setOtherList(response.data.data);
      setOtherId(response?.data?.data?.id);
      setFormData(response?.data?.data || {});
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (logindata?.token) {
      fetchOtherList();
    }
  }, [logindata?.token, userId]);

  const validateForm = () => {
    const errors = {};
    if (!formData?.job_title?.trim()) {
      errors.job_title = "Job title is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.job_title.trim())) {
      // This regex allows only alphabets and spaces
      errors.job_title =
        "Job title should not contain digits or special characters";
    }

    if (!formData?.objective?.trim()) {
      errors.objective = "Career objective is required";
    }

    if (!formData?.language_known?.trim()) {
      errors.language_known = "Language Known is required";
    } else if (
      formData.language_known.split(",").some((item) => item.trim() === "")
    ) {
      errors.language_known =
        "Each language should be separated by commas and cannot be empty";
    }

    if (!formData?.skills?.trim()) {
      errors.skills = "Skills are required";
    } else if (formData.skills.split(",").some((item) => item.trim() === "")) {
      errors.skills =
        "Each skill should be separated by commas and cannot be empty";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleOtherSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      return;
    }

    // console.log("Submitting form data", formData);

    const OtherDetails = {
      person: logindata.userId || "",
      job_title: formData.job_title || "",
      objective: formData.objective || "",
      language_known: formData.language_known || "",
      skills: formData.skills || "",
      interest: formData.interest || "",
      marital_status: formData.marital_status || "",
      nationality: formData.nationality || "India",
    };
    // console.log("other Details", OtherDetails);

    if (Object.keys(errors).length === 0) {
      setLoading(true);
      toast.dismiss();
      try {
        const updateUrl = `${urls.Update_Others}/${otherList?.id}`;
        // console.log("Update URL:", updateUrl);
        // console.log("OtherDetails payload:", OtherDetails);

        // Ensure the PUT request is sent with proper headers and body
        const response = await ResumeService.PatchApiCall(
          updateUrl,
          OtherDetails,
          logindata.token
        );
        // console.log("API response:", response);

        toast.success(response?.data?.message || "Update successful!");
        // Reset form data
        setFormData({
          job_title: "",
          objective: "",
          language_known: "",
          skills: "",
          marital_status: "",
          nationality: "India",
        });

        setOtherList(
          (prev) =>
            Array.isArray(prev)
              ? prev.map((item) =>
                  item.id === otherList?.id
                    ? { ...item, ...OtherDetails }
                    : item
                )
              : [] // Fallback to an empty array if prev is not an array
        );

        fetchOtherList();
        setIsEditMode(false);
        setTimeout(() => {
          setIsEditMode(false);
        }, 2000);
      } catch (error) {
        // console.error("Error while submitting:", error);
        setMsg(error?.response?.data?.message || "An error occurred.");
      } finally {
        setLoading(false);
        setEditIndex(null);
        setFormErrors({});
        setIsFormSubmitted(true);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate the specific field
    const errors = { ...formErrors };

    switch (name) {
      case "job_title":
        if (!value.trim()) {
          errors.job_title = "Job title is required";
        } else if (!/^[A-Za-z\s]+$/.test(value.trim())) {
          errors.job_title =
            "Job title should not contain digits or special characters";
        } else {
          delete errors.job_title;
        }
        break;
      case "objective":
        if (!value.trim()) {
          errors.objective = "Career objective is required";
        } else {
          delete errors.objective;
        }
        break;
      case "language_known":
        if (!value.trim()) {
          errors.language_known = "Language Known is required";
        } else if (value.split(",").some((item) => item.trim() === "")) {
          errors.language_known =
            "Each language should be separated by commas and cannot be empty";
        } else {
          delete errors.language_known;
        }
        break;
      case "skills":
        if (!value.trim()) {
          errors.skills = "Skills are required";
        } else if (value.split(",").some((item) => item.trim() === "")) {
          errors.skills =
            "Each skill should be separated by commas and cannot be empty";
        } else {
          delete errors.skills;
        }
        break;
    }

    setFormErrors(errors);
  };

  const nationality = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (fmr. Swaziland)",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
        className="responsive-toast"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-2 col-sm-2 col-md-4 mb-3">
            <Sidebar />
          </div>
          <div className="col-lg-8 col-8 col-sm-8 offset-3 col-md-10">
            <div className="row">
              {otherList?.job_title == null ||
              otherList?.objective == null ||
              otherList?.language_known == null ||
              otherList?.skills == null ||
              otherList?.interest == null ||
              otherList?.marital_status == null ||
              otherList?.nationality == null ? (
                // add other form
                <div className="col-md-8 col-lg-6 mx-auto">
                  <div
                    className="border border-success rounded p-4 bg-light shadow mb-4"
                    style={{ width: "100%" }}
                  >
                    <h3 className="text-center">Add Other Details</h3>
                    <hr />

                    <form className="form" onSubmit={handleOtherSubmit}>
                      <div className="form-group">
                        <label htmlFor="job_title">
                          Job Title:<span className="text-danger">*</span>
                        </label>
                        <input
                          name="job_title"
                          id="job_title"
                          className={`form-control form-control-bg ${
                            formErrors.job_title ? "is-invalid" : ""
                          }`}
                          ref={jobTitleRef}
                          maxLength={100}
                          placeholder="ex. - FullStack Developer"
                          required
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              job_title: e.target.value,
                            })
                          }
                          defaultValue={formData?.job_title || ""}
                        />
                        {formErrors.job_title && (
                          <div className="invalid-feedback">
                            {formErrors.job_title}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="career">
                          Career Objective:
                          <span className="text-danger">*</span>
                        </label>
                        <textarea
                          name="objective"
                          id="objective"
                          className={`form-control align-justify form-control-bg ${
                            formErrors.objective ? "is-invalid" : ""
                          }`}
                          ref={careerRef}
                          maxLength={5000}
                          defaultValue={formData?.objective || ""}
                          onChange={handleInputChange}
                          placeholder="Enter career"
                          required
                          rows={10}
                          cols={50}
                        />
                        {formErrors.objective && (
                          <div className="invalid-feedback">
                            {formErrors.objective}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="language_known">
                          Language Known:<span className="text-danger">*</span>
                        </label>
                        <input
                          name="language_known"
                          id="language_known"
                          className={`form-control form-control-bg ${
                            formErrors.language_known ? "is-invalid" : ""
                          }`}
                          ref={languageKnownRef}
                          maxLength={150}
                          placeholder="ex.- Hindi, English"
                          required
                          defaultValue={formData?.language_known || ""}
                          onChange={handleInputChange}
                        />
                        {formErrors.language_known && (
                          <div className="invalid-feedback">
                            {formErrors.language_known}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="skills">
                          Skills:<span className="text-danger">*</span>
                        </label>
                        <input
                          name="skills"
                          id="skills"
                          className={`form-control form-control-bg ${
                            formErrors.skills ? "is-invalid" : ""
                          }`}
                          ref={skillsRef}
                          maxLength={100}
                          placeholder="ex.- HTML, CSS, Bootstrap, Python"
                          required
                          defaultValue={formData?.skills || ""}
                          onChange={handleInputChange}
                        />
                        {formErrors.skills && (
                          <div className="invalid-feedback">
                            {formErrors.skills}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="interest">Interest:</label>
                        <textarea
                          name="interest"
                          id="interest"
                          className={`form-control form-control-bg ${
                            formErrors.interest ? "is-invalid" : ""
                          }`}
                          ref={interestRef}
                          maxLength={5000}
                          defaultValue={formData?.interest || "-"}
                          onChange={handleInputChange}
                        />
                        {formErrors.interest && (
                          <div className="invalid-feedback">
                            {formErrors.interest}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="marital_status">Marital Status:</label>
                        <select
                          name="marital_status"
                          id="marital_status"
                          className={`form-control form-control-bg ${
                            formErrors.marital_status ? "is-invalid" : ""
                          }`}
                          ref={maritalStatusRef}
                          onChange={handleInputChange}
                          defaultValue={formData?.marital_status || "-"}
                        >
                          <option value="">---------</option>
                          <option value="Married">Married</option>
                          <option value="Unmarried">Unmarried</option>
                          <option value="None">None</option>
                        </select>
                        {formErrors.marital_status && (
                          <div className="invalid-feedback">
                            {formErrors.marital_status}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="nationality">Country:</label>
                        <select
                          name="nationality"
                          id="nationality"
                          className={`form-control form-control-bg ${
                            formErrors.nationality ? "is-invalid" : ""
                          }`}
                          ref={nationalityRef}
                          defaultValue={formData?.nationality || ""}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Country</option>
                          {nationality.map((country) => (
                            <option key={country} value={country}>
                              {country}
                            </option>
                          ))}
                        </select>
                        {formErrors.nationality && (
                          <div className="invalid-feedback">
                            {formErrors.nationality}
                          </div>
                        )}
                      </div>

                      <button type="submit" className="btn btn-md  btn-success">
                        Save
                      </button>
                    </form>
                  </div>
                </div>
              ) : (
                <>
                  {!isEditMode && (
                    <div className="col-md-8 col-lg-6 mx-auto">
                      <div className="border border-success rounded p-4 bg-light shadow">
                        <div className="table-responsive table-responsive-resume">
                          <table className="table table-other table-striped table-rounded">
                            <tbody>
                              <tr>
                                <th scope="row">Job Title</th>
                                <td>{otherList?.job_title}</td>
                              </tr>
                              <tr>
                                <th scope="row">Career Objective</th>
                                <td>{otherList?.objective || ""}</td>
                              </tr>
                              <tr>
                                <th scope="row">Language Known</th>
                                <td>{otherList?.language_known || ""}</td>
                              </tr>
                              <tr>
                                <th scope="row">Skills</th>
                                <td>{otherList?.skills || ""}</td>
                              </tr>
                              <tr>
                                <th scope="row">Interest</th>
                                <td>{otherList?.interest || "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Marital Status</th>
                                <td>{otherList?.marital_status || "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Country</th>
                                <td>{otherList?.nationality || ""}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {isEditMode ? (
                          <button
                            className="btn btn-md btn-secondary mb-2 mt-3"
                            onClick={() => setIsEditMode(false)}
                          >
                            Cancel
                          </button>
                        ) : (
                          <button
                            className="btn btn-md btn-success mb-2 mt-3"
                            onClick={() => setIsEditMode(true)}
                          >
                            {loading ? "     Editing..." : "Edit"}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
              {/* edit Form Section */}

              {isEditMode && (
                <div className="col-md-8 col-lg-6 mx-auto mi">
                  <div
                    className="border border-success rounded p-4 bg-light shadow mb-4"
                    style={{ width: "100%" }}
                  >
                    <h3 className="text-center">Edit Other Details</h3>
                    <hr />

                    <form className="form" onSubmit={handleOtherSubmit}>
                      <div className="form-group">
                        <label htmlFor="job_title">
                          Job Title:<span className="text-danger">*</span>
                        </label>
                        <input
                          name="job_title"
                          id="job_title"
                          className={`form-control form-control-bg ${
                            formErrors.job_title ? "is-invalid" : ""
                          }`}
                          ref={jobTitleRef}
                          maxLength={100}
                          placeholder="ex. - FullStack Developer"
                          required
                          defaultValue={formData?.job_title || ""}
                          onChange={handleInputChange}
                        />
                        {formErrors.job_title && (
                          <div className="invalid-feedback">
                            {formErrors.job_title}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="career">
                          Career Objective:
                          <span className="text-danger">*</span>
                        </label>
                        <textarea
                          name="career"
                          id="career"
                          className={`form-control align-justify form-control-bg ${
                            formErrors.objective ? "is-invalid" : ""
                          }`}
                          ref={careerRef}
                          maxLength={5000}
                          value={formData.objective}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              objective: e.target.value,
                            })
                          }
                          placeholder="Enter career"
                          required
                        />
                        {formErrors.objective && (
                          <div className="invalid-feedback">
                            {formErrors.objective}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="language_known">
                          Language Known:<span className="text-danger">*</span>
                        </label>
                        <input
                          name="language_known"
                          id="language_known"
                          className={`form-control form-control-bg ${
                            formErrors.language_known ? "is-invalid" : ""
                          }`}
                          ref={languageKnownRef}
                          maxLength={150}
                          placeholder="ex.- Hindi, English"
                          required
                          defaultValue={formData?.language_known || ""}
                          onChange={handleInputChange}
                        />
                        {formErrors.language_known && (
                          <div className="invalid-feedback">
                            {formErrors.language_known}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="skills">
                          Skills:<span className="text-danger">*</span>
                        </label>
                        <textarea
                          name="skills"
                          id="skills"
                          className={`form-control form-control-bg ${
                            formErrors.skills ? "is-invalid" : ""
                          }`}
                          ref={skillsRef}
                          maxLength={250}
                          placeholder="ex.- HTML, CSS, Bootstrap, Python"
                          required
                          defaultValue={formData?.skills || ""}
                          onChange={handleInputChange}
                        />
                        {formErrors.skills && (
                          <div className="invalid-feedback">
                            {formErrors.skills}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="interest">Interest:</label>
                        <textarea
                          name="interest"
                          id="interest"
                          className={`form-control form-control-bg ${
                            formErrors.interest ? "is-invalid" : ""
                          }`}
                          ref={interestRef}
                          maxLength={5000}
                          placeholder="ex.- Cricket, Football"
                          defaultValue={formData?.interest || ""}
                          onChange={handleInputChange}
                        />
                        {formErrors.interest && (
                          <div className="invalid-feedback">
                            {formErrors.interest}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="marital_status">Marital Status:</label>
                        <select
                          name="marital_status"
                          id="marital_status"
                          className={`form-control form-control-bg ${
                            formErrors.marital_status ? "is-invalid" : ""
                          }`}
                          ref={maritalStatusRef}
                          defaultValue={formData?.marital_status || ""}
                          onChange={handleInputChange}
                        >
                          <option value="">---------</option>
                          <option value="Married">Married</option>
                          <option value="Unmarried">Unmarried</option>
                          <option value="None">None</option>
                        </select>
                        {formErrors.marital_status && (
                          <div className="invalid-feedback">
                            {formErrors.marital_status}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="nationality">Country:</label>
                        <select
                          name="nationality"
                          id="nationality"
                          className={`form-control form-control-bg ${
                            formErrors.nationality ? "is-invalid" : ""
                          }`}
                          ref={nationalityRef}
                          defaultValue={formData?.nationality || ""}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Country</option>
                          {nationality.map((country) => (
                            <option key={country} value={country}>
                              {country}
                            </option>
                          ))}
                        </select>
                        {formErrors.nationality && (
                          <div className="invalid-feedback">
                            {formErrors.nationality}
                          </div>
                        )}
                      </div>

                      <button
                        type="submit"
                        className="btn btn-md  btn-success mt-3 "
                      >
                        {loading ? "Updating..." : "Update"}
                      </button>
                      <button
                        type="button"
                        className="btn btn-md btn-secondary mt-3 ms-2"
                        onClick={() => setIsEditMode(false)}
                      >
                        Cancel
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SOthers;
