

import React, { useEffect, useState } from "react";
import HrService from "../../services/HrService";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaStar, FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

const HrShortlistedCandidates = () => {
  const loginUser = useSelector((state) => state.user.value);
  const [shortlistedCandidates, setShortlistedCandidates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [candidateIds, setCandidateIds] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchError, setSearchError] = useState("");
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  useEffect(() => {
    fetchShortlistedCandidates();
  }, []);

  const fetchShortlistedCandidates = async () => {
    setIsLoading(true);
    try {
      const response = await HrService.shortlisted(loginUser.userId, loginUser.token);
      // console.log("API Response:", response);

      if (response && response.data && response.data.shortlisted_candidates.length > 0) {
        const candidates = response.data.shortlisted_candidates.map((cand) => ({
          shortlistId: cand.id,
          name: cand.name,  
          email: cand.email, 
          contact: cand.contact,  
          education:cand.education,
          isShortlisted: cand.shortlisted ,
        }));
        setCandidateIds(candidates);
        setShortlistedCandidates(candidates);
      } else {
        setShortlistedCandidates([]);
      }
    } catch (error) {
      setErrorMessage("Failed to fetch shortlisted candidates.");
   
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteShortlist = async (candidateId, isShortlisted) => {
    setIsLoading(true);

    const data = {
      shortlisted: !isShortlisted,
    };

    try {
      const response = await HrService.deleteShortlisted(data, candidateId, loginUser.token);
      if (response?.status === 200) {
        if (isShortlisted) {
          setShortlistedCandidates(prevCandidates =>
            prevCandidates.filter(candidate => candidate.shortlistId !== candidateId)
          );
        } else {
          setShortlistedCandidates(prevCandidates => 
            prevCandidates.map(candidate =>
              candidate.shortlistId === candidateId
                ? { ...candidate, isShortlisted: !isShortlisted }
                : candidate
            )
          );
        }

        toast.success(`Candidate has been ${!isShortlisted ? "shortlisted" : "unshortlisted"} successfully!`);
      } else {
        toast.error("Failed to update shortlist.");
      }
    } catch (error) {
 
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    if (!searchValue) {
      setShowValidationMessage(true);
      setSearchError("Please enter a valid name or email.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[a-zA-Z ]+$/;
    if (nameRegex.test(searchValue)) {
      setSearchError("");
    }

    else if (!emailRegex.test(searchValue)) {
      setShowValidationMessage(true);
      setSearchError("Please enter a valid email.");
      return;
    }

    setSearchError(""); 
    setShowValidationMessage(false);

    const filteredCandidates = shortlistedCandidates.filter(candidate => 
      candidate.user?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      candidate.user?.email.toLowerCase().includes(searchValue.toLowerCase())
    );

    setShortlistedCandidates(filteredCandidates);

    if (filteredCandidates.length === 0) {
      toast.error("No candidates found matching your search.");
      fetchShortlistedCandidates();
    } else {
      setErrorMessage("");
    }
  };

  const handleCancelSearch = () => {
    setSearchValue("");
    setSearchError("");
    setShowValidationMessage(false);
    fetchShortlistedCandidates(); 
  };

  return (
    <div className="container mt-4 ">
      <ToastContainer position="top-center" autoClose={2000} className="responsive-toast" />
      <div className="row d-flex align-items-center justify-content-lg-between justify-content-md-between justify-content-center mb-5">
        <div className="col-lg-2 col-2 col-md-2 col-sm-2">
         
           <button
              className="btn bk-hr back-button"
              onClick={() => {
                navigate("/studentDetail");
                window.scrollTo(0, 0);
              }}
            >
              Back
            </button>
        </div>

        <div className="col-sm-10 col-10 col-lg-4 col-md-4 text--lg-end text-center text-sm-center text-md-end text-align-center justify-content-center">
          <h4 className="text-align-center justify-content-center">My Shortlisted Candidates</h4>
        </div>
        <div className="col-8 col-sm-6 col-lg-3 col-md-5 justify-content-center justify-content-sm-center justify-content-lg-end justify-content-md-end d-flex position-relative4 mt-3 mt-lg-0 mt-md-0">
          <div className="d-flex flex-column">
            <input
              type="text"
              className={`form-control ${searchError ? "is-invalid" : ""}`}
              placeholder="Name or Email"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                if (searchError) {
                  setSearchError("");
                  setShowValidationMessage(false);
                }
              }}
            />
            {searchValue && (
              <span
                className={`clear-input-icon4 ${searchError ? "move-icon4" : ""}`}
                onClick={handleCancelSearch}
              >
                <i className="fa fa-times" />
              </span>
            )}
            {showValidationMessage && searchError && (
              <small className="text-danger mt-1 validation-message text-center">{searchError}</small>
            )}
          </div>
          <div className=" p-1 ms-2">
            <button
              className="btn btn-md btn-green"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>

      </div>

      {isLoading && 
          <div className="text-center">
          <span> Loading</span>{" "}
       <span
         className="spinner-border spinner-border-sm"
         role="status"
         aria-hidden="true"
       ></span>
      
     </div>
      }
      {errorMessage && <div className="text-center mt-3"><span>{errorMessage}</span></div>}
      {shortlistedCandidates.length === 0 && !isLoading && (
        <div className="text-center mt-3"><span>No shortlisted candidates found.</span></div>
      )}

      {shortlistedCandidates.length > 0 && (
        <div className="table-responsive mt-4">
          <table className="table table-bordered">
            <thead>
              <tr className="text-center">
                <th>S.no</th>
                <th>Name</th>
                <th>Education</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {shortlistedCandidates.map((candidate, index) => (
                <tr key={candidate.id} className="text-center">
                  <td>{index + 1}</td>
                  <td>{candidate?.name}</td>
                  <td>{candidate?.education}</td>
                  <td>{candidate?.contact}</td>
                  <td>{candidate?.email}</td>
                  <td>
                    <button
                      className="btn btn-green btn-md"
                      disabled={isLoading}
                      onClick={() => handleDeleteShortlist(candidate.shortlistId, candidate.isShortlisted)}
                    >
                      <FaStar className="me-2" />
                      {candidate.isShortlisted ? "UnShortlist" : "Shortlist"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default HrShortlistedCandidates;

