
import React, { useState, useEffect, createContext, useContext } from "react";
import { FaWifi, FaTimesCircle } from "react-icons/fa";

import { RiWifiOffLine } from "react-icons/ri";

const OnlineStatusContext = createContext(false);

export const StatusProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={isOnline}>
      <Status />
      {children}
    </OnlineStatusContext.Provider>
  );
};

const Status = () => {
  const isOnline = useContext(OnlineStatusContext);
  const [visible, setVisible] = useState(isOnline);

  useEffect(() => {
    if (isOnline) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, 3000);

      return () => clearTimeout(timer);
    } else {

      setVisible(true);
    }
  }, [isOnline]);

  if (!visible && isOnline) return null; 

  return (
    <div
      style={{
        zIndex: "9999",
        position: "fixed",
        top: "12px",
        right: "10px",
        color: isOnline ? "green" : "red",
        backgroundColor: "white",
        padding: "10px 20px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        fontWeight: "bold",
        fontSize: "16px",
        opacity: visible || !isOnline ? 1 : 0,
        transition: "opacity 0.5s ease-in-out", 
      }}
    >
      {isOnline ? (
        <>
          <FaWifi />
          &nbsp; You are online
        </>
      ) : (
        <>
          <RiWifiOffLine />
          &nbsp; You are offline
        </>
      )}
    </div>
  );
};

const App = () => {
  return (
    <StatusProvider>
      <div style={{ padding: "20px" }}>
        <h1>React Online/Offline Status Toast</h1>
        <p>Toggle your network connection to see the toast notifications.</p>
      </div>
    </StatusProvider>
  );
};

export default App;
