import React, { useEffect, useState, useRef } from "react";
import AdminService from "../../services/AdminService";
import CoursesService from "../../services/CoursesService";
import withRouter from "../withRouter";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Admin.css";

function SaveCourse(props) {
  const [savecourse, setSaveCourse] = useState([]);
  const [courselist, setCourseList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [msg, setMsg] = useState("");
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseTitle, setCourseTitle] = useState("");
  const [description, setDescription] = useState("");
  const [activeStatus, setActiveStatus] = useState();
  const [image, setImage] = useState(null); // New state for image
  const [imagePreview, setImagePreview] = useState(null); // Preview state

  const [isUpdating, setIsUpdating] = useState(false);
  const loginUser = useSelector((state) => state.user.value);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const imageInputRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    CoursesService.getAllCoursesList(loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        setCourseList(data?.course?.course);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [isDataUpdated]);

  useEffect(() => {
    if (isDataUpdated) {
      setMsg("");
      setSelectedCourse(null);
      setCourseTitle("");
      setDescription("");
      setActiveStatus();
      setImage(null); // Clear image on data update
      setImagePreview(null);
      setIsUpdating(false);
    }
  }, [isDataUpdated]);

  const save = (event) => {
    event.preventDefault();

    const course_title = event?.target?.coursebox?.value;
    const description = event?.target?.descbox?.value;
    const active_status = event?.target?.statusbox?.checked ? 1 : 0;

    const formData = new FormData();
    formData.append("course_title", course_title);
    formData.append("description", description);
    formData.append("active_status", active_status);
    if (image) formData.append("image", image);

    toast.dismiss();
    //console.log("formData", formData)
    AdminService.addCourse(formData, loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        toast.success("Skill Saved successfully!");
        setCourseList((prevCourseList) => [...prevCourseList, data]);
        setIsDataUpdated(!isDataUpdated);
        event.target.reset();
        setImage(null); 
        setImagePreview(null); 
        imageInputRef.current.value = null;
      })
      .catch((error) => {
        toast.error("Saving failed. Please try again.");
      });
  };

    const delcourse = (course_id) => {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this course with its associated modules?"
      );
      if (confirmDelete) {
        toast.dismiss();
        AdminService.deleteCourse(course_id, loginUser.token)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to delete the course. Please try again.");
            }
            return response.json();
          })
          .then((data) => {
            toast.success("Course deleted successfully!");
            // Update the course list after deletion
            setCourseList((prevCourseList) =>
              prevCourseList.filter((course) => course._id !== course_id)
            );
            setIsDataUpdated((prev) => !prev);
          })
          .catch((error) => {
            // console.error("Error deleting course:", error);
            // toast.error("An error occurred while deleting the course.");
          });
      }
    };

  const updateCourseData = (event) => {
    event.preventDefault();

    if (selectedCourse) {
      const updatedCourse = {
        course_id: selectedCourse.course_id,
        course_title: courseTitle,
        description: description,
        active_status: activeStatus,
        image: image,
      };

      const formData = new FormData();
      formData.append("course_title", updatedCourse.course_title);
      formData.append("description", updatedCourse.description);
      formData.append("active_status", updatedCourse.active_status);
      // if (updatedCourse.image) formData.append("image", updatedCourse.image);
      if (updatedCourse.image) {
        formData.append("image", updatedCourse.image);
      } else if (selectedCourse.image) {
        formData.append("image", selectedCourse.image); // Use the old image URL if no new image is provided
      }

      toast.dismiss();
      AdminService.updateCourse(selectedCourse.course_id, formData, loginUser.token)
        .then((response) => response.json())
        .then((data) => {
          toast.success("Course Updated successfully!");
          setCourseList((prevCourseList) =>
            prevCourseList.map((course) =>
              course.course_id === selectedCourse.course_id ? data : course
            )
          );
          setIsDataUpdated(!isDataUpdated); // Trigger data update

          setSelectedCourse(null);
          setCourseTitle("");
          setDescription("");
          setActiveStatus("");
          setImage(null); // Reset image after update
          setImagePreview(null); 
          imageInputRef.current.value = null;
        })
        .catch((error) => {
          toast.error("Update failed. Please try again.");
        });
    }
  };

  const cancelUpdate = () => {
    setSelectedCourse(null);
    setCourseTitle("");
    setDescription("");
    setActiveStatus("");
    setImage(null); // Reset image
    setImagePreview(null); 
    setIsUpdating(false); // Switch to save mode
  };

  const upCourse = (course) => {
      window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setSelectedCourse(course);
    setCourseTitle(course?.course_title);
    setDescription(course?.description);
    setActiveStatus(course?.active_status);
    setImagePreview(course?.image || null);
    setIsUpdating(true);
  };

  const indexOfLastCourse = currentPage * perPage;
  const indexOfFirstCourse = indexOfLastCourse - perPage;
  const currentCourses = courselist.slice(indexOfFirstCourse, indexOfLastCourse);

  const nextData = () => {
    setCurrentPage(currentPage + 1);
  };

  const preData = () => {
    setCurrentPage(currentPage - 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const totalPages = Math.ceil(courselist.length / perPage);
    const range = 2;
    const startPage = Math.max(1, currentPage - range);
    const endPage = Math.min(totalPages, currentPage + range);

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (startPage > 1) pageNumbers.unshift("...");
    if (endPage < totalPages) pageNumbers.push("...");

    return pageNumbers;
  };


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      const previewUrl = URL.createObjectURL(file);
      console.log("Preview URL:", previewUrl); // Check if the preview URL is correct
      setImagePreview(previewUrl);
    }
  };
  

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
        className="responsive-toast"
      />
      <div className="container formRef">
        <div className="row d-flex align-items-center justify-content-between mb-5">
          <div className="col-lg-2 col-2 col-md-2 col-sm-2">
            <button
              className="btn btn-md bk-adm back-button "
              onClick={() => navigate("/user")}
            >
              Back
            </button>
          </div>

          <div className="col-lg-8 col-8 col-md-8 col-sm-8 text-center align-content-center">
            <h4 className="mb-0">New Skill</h4>
          </div>

          <div className="col-lg-2 col-2 col-md-2 col-sm-2 text-end"></div>
        </div>

        <form onSubmit={isUpdating ? updateCourseData : save}>
          <div className="col-lg-12 col-sm-12 col-md-12">
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Skill</label>
              <div className="col-sm-4 col-lg-4 col-md-6">
                <input
                  type="text"
                  name="coursebox"
                  className="form-control"
                  id="coursebox"
                  placeholder="Enter Skill"
                  required
                  value={courseTitle}
                  onChange={(event) => setCourseTitle(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Description</label>
              <div className="col-sm-4 col-lg-4 col-md-6">
                <input
                  type="text"
                  name="descbox"
                  className="form-control"
                  id="descbox"
                  placeholder="Enter Description..."
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Active-Status</label>
              <div className="col-sm-4">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="statusSwitch"
                    style={{ width: "50px", height: "28px" }}
                    checked={activeStatus === 1}
                    onChange={(event) =>
                      setActiveStatus(event?.target?.checked ? 1 : 0)
                    }
                  />
                  <label className="form-check-label ms-2 mb-0 mt-1" htmlFor="statusSwitch">
                    {activeStatus === 1 ? "Yes" : "No"}
                  </label>
                </div>
              </div>
            </div>


<div className="form-group row">
  <label className="col-sm-2 col-form-label">Image</label>
  <div className="col-sm-4 col-lg-4 col-md-6">
    {/* Display current image or preview image */}
    {imagePreview ? (
      <div>
        <img
          src={imagePreview}
          alt="New Image Preview"
          style={{ width: "100px", height: "100px", objectFit: "cover" }}
        />
      </div>
    ) : (
      selectedCourse && selectedCourse.image && (
        <div>
          <img
            src={selectedCourse.image}
            alt="Current Image"
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
        </div>
      )
    )}

    {/* File input to allow image upload */}
    <input
      type="file"
      name="image"
      className="form-control"
      id="image"
      onChange={handleImageChange}  // Handle image selection
      ref={imageInputRef}
    />
  </div>
</div>


            <div className="form-group row">
              <div className="col-sm-8 offset-sm-2 text-right mt-3">
                {isUpdating && selectedCourse ? (
                  <>
                    <button
                      type="submit"
                      className="btn btn-md btn-green"
                      style={{ borderRadius: "10px" }}
                    >
                      Update
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-md btn-secondary"
                      style={{ borderRadius: "10px" }}
                      onClick={cancelUpdate}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-md btn-green"
                    style={{ borderRadius: "10px" }}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
          &nbsp;
          <b className="text-success">{msg}</b>
        </form>
      </div>
      <br />
      {isLoading ? (
        <div className="text-center">
          <span> Loading </span>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      ) : (
        <div className="container">
          {currentCourses.length > 0 && (
            <div className="table-responsive">
              <table className="table table-bordered table-fixed ">
                <thead>
                  <tr className="text-center">
                    <th className="fixed-header">S.no</th>
                    <th className="fixed-header">Skill Name</th>
                    <th className="fixed-header">Description</th>
                    <th className="fixed-header">Image</th>
                    <th className="fixed-header">Active-Status</th>
                    <th className="fixed-header">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentCourses.map((ob, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "justify" }}>
                        {index + 1 + (currentPage - 1) * perPage}
                      </td>
                      <td style={{ textAlign: "justify" }}>{ob.course_title}</td>
                      <td style={{ textAlign: "justify" }}>{ob.description}</td>
<td className="text-center">
{ob.image && ob.image !== "" ? (
          <img
            src={ob.image} 
            style={{ width: "50px", height: "50px", objectFit: "cover" }}
          />
        ) : (
          "No image"
        )}
</td>

                      <td className="text-center">
                        {ob.active_status ? "Yes" : "No"}
                      </td>
                      <td className="d-flex">
                        <button
                          className="btn btn-md btn-secondary"
                          style={{ borderRadius: "10px" }}
                          onClick={() => upCourse(ob)}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-md btn-danger d-flex"
                          style={{ borderRadius: "10px" }}
                          onClick={() => delcourse(ob.course_id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {currentCourses.length > 0 && (
            <div className="pagination-container justify-content-center d-flex">
              <button
                type="button"
                className="btn btn-md btn-dark"
                onClick={preData}
                disabled={currentPage === 1}
              >
                &larr; Previous
              </button>

              {getPageNumbers().map((page, index) =>
                page === "..." ? (
                  <span key={index} className="pagination-dots">
                    ...
                  </span>
                ) : (
                  <button
                    key={index}
                    className={`btn btn-md btn-dark m-1 ${
                      currentPage === page ? "active" : ""
                    }`}
                    onClick={() => handlePageClick(page)}
                  >
                    {page}
                  </button>
                )
              )}

              <button
                type="button"
                className="btn btn-md btn-dark"
                onClick={nextData}
                disabled={currentPage === Math.ceil(courselist.length / perPage)}
              >
                Next &rarr;
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default withRouter(SaveCourse);
