import { Component } from "react";
import { Link } from "react-router-dom";
import "./CourseItem.css";

import { useSelector } from "react-redux";

export default function CourseItem(props) {
  const loginUser = useSelector((state) => state.user.value);

  return (
    <>
   

      <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12 courseitem shadow-sm p-2  mb-1  bg-white rounded text-center">

        <Link
          to={
            loginUser.islogin
              ? "/course/module/" + props.course.course_id
              : "/login"
          }
        >
          <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
            <img
              src={props.course.image}
              width={100}
              height={90}
              className=" mx-auto d-block"
            />
            {/* <img src={props.course.image} width={100} /> */}
          </div>
        </Link>
        <br></br>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
          <Link
            to={
              loginUser.islogin
                ? "/course/module/" + props.course.course_id
                : "/login"
            }
          >
            <h5 className="text-green">{props.course.course_title}</h5>
          </Link>
        </div>
      </div>
    </>
  );
}
