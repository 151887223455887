const SERVER = process.env.REACT_APP_LIVE_SKILLEDFRESHER_SERVER;

class LoginService {
  LoginUser(data) {
    // console.log(">>>>>>>>>>>>>>>>>> ", data)
    // const token = req.user

    return fetch(`${SERVER}/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  getCsrfToken() {
    const cookies = document.cookie.split("; ");
    const csrfToken = cookies.find((row) => row.startsWith("csrftoken="));
    return csrfToken ? csrfToken.split("=")[1] : "";
  }
}
export default new LoginService();
