const SERVER = process.env.REACT_APP_LIVE_SKILLEDFRESHER_SERVER;

class TestService {
  constructor() {
    this.token = localStorage.getItem("token");
  }

  getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  saveTestByUserId(userid, moduleId, quesLevel, date, answer, totalQ, token) {
    let params = {
      test: {
        userid: userid,
        moduleId: moduleId,
        quesLevel: quesLevel,
        date: date,
        totalQ: totalQ,
      },
      answer: answer,
    };
    return fetch(`${SERVER}/api/test/savetest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify(params),
    });
  }

  getTestAnswer(userid, token) {
    return fetch(`${SERVER}/api/test/listById`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify({ userid }),
    });
  }

  getTestByTestId(testid, token) {
    return fetch(`${SERVER}/api/test/` + testid, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    });
  }

  getModuleByTestId(testid, token) {
    return fetch(`${SERVER}/api/test/module/` + testid, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    });
  }

  getQuesCountByTestId(testid) {
    return fetch(`${SERVER}/api/test/attend/` + testid, {
      headers: this.getHeaders(),
    });
  }

  checkTestLimit(userid, moduleId, quesLevel, token) {
    const url = `${SERVER}/api/test/checkTestLimit?userid=${userid}&moduleId=${moduleId}&quesLevel=${quesLevel}`;
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    });
  }
}

export default new TestService();
