import React from "react";
import Temp1 from "../Template/Temp1";
import Temp2 from "../Template/Temp2";
import Temp3 from "../Template/Temp3";
import Temp4 from "../Template/Temp4";
import Temp5 from "../Template/Temp5";
import Temp6 from "../Template/Temp6";
import { useSelector } from "react-redux";
import Temp7 from "../Template/Temp7";
import Temp8 from "../Template/Temp8";
import Temp9 from "../Template/Temp9";
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";

const Templates = () => {
const SERVER = process.env.REACT_APP_LIVE_SKILLEDFRESHER_SERVER;

  const candidateInfo = JSON.parse(localStorage.getItem("CandidateInfo"));
  const loginUser = useSelector((state) => state.user.value);
  const profileImage = useSelector((state) => state.profile.profileImage);
  const navigate = useNavigate();
  return (
    <>
      <div className="container-lg container-fluid mt-md-3">
        <div className="row container-fluid-sm container-fluid container-lg  mb-lg-4">
          <div className="col-lg-2 col-md-2 col-sm-2 col-2">
            <div className="container-lg">
              <button
                className="btn bk-temp back-button"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-10 ">
            <div className="ms-5 ms-lg-0 ms-sm-5 ms-md-0"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              
        
               {candidateInfo?.data?.data?.image || profileImage ? (
    <img
      src={
        profileImage ||
        `${SERVER}/upload/user_image/${candidateInfo?.data?.data?.image}`
      }
      style={{
        borderRadius: "50%",
        width: "70px",
        height: "70px",
        objectFit: "cover",
      }}
    />
  ) : (
    <FaUserCircle
    style={{
      width: "70px",
      height: "70px",
      color: "#ccc",
     }}
    />
  )}
              &nbsp;&nbsp;
              <span
                style={{
                  fontSize: "2em",
                  fontFamily: "serif",
                  fontWeight: "100",
                }}
              >
                {loginUser.username}
              </span>
            </div>
          </div>
        </div>

        <div className="justify-content-center container-lg">
          <hr style={{ textAlign: "center" }} />
          <div
            className="row gy-4 justify-content-center justify-content-space-between justfy-content-md-space-between"
            style={{ textAlign: "center" }}
          >
            <div className="col-12 col-sm-5 col-md-6 col-lg-4 d-flex justify-content-center">
              <Temp1 />
            </div>
            <div className="col-12 col-sm-5 col-md-6 col-lg-4 d-flex justify-content-center">
              <Temp2 />
            </div>
            <div className="col-12 col-sm-5 col-md-6 col-lg-4 d-flex justify-content-center ">
              <Temp3 />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 d-flex justify-content-center">
              <Temp4 />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 d-flex justify-content-center">
              <Temp5 />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 d-flex justify-content-center">
              <Temp6 />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 d-flex justify-content-center">
              <Temp7 />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 d-flex justify-content-center">
              <Temp8 />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 d-flex justify-content-center">
              <Temp9 />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Templates;
