import React from "react";
import QuestionsService from "../../services/QuestionsService";
import TestService from "../../services/TestService";
import withRouter from "../withRouter";
import { Link } from "react-router-dom";
import "./QuestionItem.css";
import QusItem from "./QusItem";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModulesService from "../../services/ModulesService";
import { useNavigate } from "react-router-dom";
import { setQues } from "../../reduxdata/QuestSlice";

function Question(props) {
  const loginUser = useSelector((state) => state?.user?.value);


  const [questions, setQuestions] = useState([]);
  const [test, setTest] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [module, setModule] = useState([]);
  const [questionsNotFound, setQuestionsNotFound] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [level, setLevel] = useState("");
  const [courseTitle, setCourseTitle] = useState("");
  const navigate = useNavigate();
  var dispatch = useDispatch();
  // console.log("+++++++++++++", test, answer);

  //  let {id} =useParams();

  var onSave = () => {
 

    if (answer.length == 0) {
      setValidationError(
        "You must attempt at least one question before submitting."
      );
      return;
    }
    setValidationError("");
    var currentDate = new Date();

    const userid = loginUser.userId;
    const moduleId = props.params.moduleId;
    const quesLevel = props.params.quesLevel;
    const date =
      currentDate.getFullYear() +
      "/" +
      (currentDate.getMonth() + 1) +
      "/" +
      currentDate.getDate();
    const totalQ = questions.length;
    // console.log("date", date)
    // console.log("userid", userid)

    // console.log(userid, moduleId, quesLevel, date)
    // var t=setTest(test)
    TestService.saveTestByUserId(
      userid,
      moduleId,
      quesLevel,
      date,
      answer,
      totalQ,
      loginUser.token
    )
      .then((response) => response.json())
      .then((data) => {
        setTest(data.test);
        navigate("/course/success");
        // console.log(data.test)
      });
  };
  var removeAnswer = (qid) => {
    const updatedAnswers = answer.filter((item) => item.qid !== qid);
    setAnswer(updatedAnswers);
  };

  var submitAnswer = (qid, answers, correctAns, totalQ) => {
    if (answers == null) {
      removeAnswer(qid);
      return;
    }
  
    const ans = answer.find((ob) => ob.qid === qid);
    let arr;
    if (ans === undefined) {
      arr = [...answer, { qid, answers, correctAns, totalQ }];
    } else {
      arr = answer.map((ob) =>
        ob.qid === qid ? { qid, answers, correctAns, totalQ } : ob
      );
    }
    setAnswer(arr);
      // Clear the validation error if any question is answered
  if (arr.length > 0) {
    setValidationError("");
  }

  };
  
  useEffect(() => {
    const storedCourseTitle = localStorage.getItem("course_title");
    setCourseTitle(storedCourseTitle || "");

    const quesLevel = props?.params?.quesLevel;
    const moduleId = props?.params?.moduleId;
    const userid = loginUser.userId;

    QuestionsService.getQuestionById(
      quesLevel,
      moduleId,
      userid,
      loginUser.token
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.question?.question?.length > 0) {
          // console.log("my response", data);
          setQuestions(data?.question?.question);
          const Question = dispatch(
            setQues([{ ques: data?.question?.question }])
          );
          // console.log("my Question", Question);
          setLevel(data?.level);
        } else {
          setQuestionsNotFound(true);
        }
      });

    const module_id = props.params.moduleId;
    ModulesService.getModuleById(module_id, loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        setModule(data?.module?.data);
      });
  }, []);


  return (
    <>
      <div>
        <div className="container mt-3 mt-lg-5 mt-md-5 mt-sm-4">
       
          <div className="row mb-0 mb-4">
            <div className="col-12 col-lg-2 col-md-2  mb-sm-4 mb-2">
              <button
                className="btn bk-hr back-button"
                onClick={() => {
                  navigate(-1);
                  window.scrollTo(0, 0);
                }}
              >
                Back
              </button>
            </div>

            <div className="col-12 col-lg-6 col-md-6 d-flex flex-column justify-content-center">
              <div className="module1 justify-content-between align-items-center">
                <div className="position-sticky">
                  Skill : &nbsp; {courseTitle}
                  <br />
                  Module : &nbsp;{" "}
                  {module?.map((ob) => ob.module_title).join(", ")}
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 col-md-4 text-end">
              <div className="module2 justify-content-between align-items-left ">
                <div className="position-sticky">
                  Question: &nbsp; {questions.length}
                  <br />
                  Level: &nbsp; {level}
                </div>
              </div>
            </div>
          </div>

          {/* <div className='row'> */}
          <div className="col-lg-12 col-md-12 col-sm-12">
            {/* <form onSubmit={onSave}> */}
            <form>
              <div
                className="content-scrollable"
                style={{
                  maxHeight: "calc(100vh - 150px)",
                  overflowY: "auto",
                  scrollbarWidth: "none",
                }}
              >
                {Object.keys(questions).length > 0 ? (
                  questions?.map((ques, index) => (
                    <QusItem
                      key={index}
                      qus={ques}
                      index={index + 1}
                      submitAnswer={submitAnswer}
                    />
                  ))
                ) : questionsNotFound ? (
                  <h5 className="text-center mt-5 fw-light">
                    Questions may be updated soon. Please check back later.
                  </h5>
                ) : (
                  <div className="text-center">
                  <span> Loading</span>{" "}
               <span
                 className=" spinner-border spinner-border-sm"
                 role="status"
                 aria-hidden="true"
               ></span>
              
             </div>
                )}
              </div>
            </form>
          </div>
        </div>
        {questions.length > 0 && (
          <div className="submitbtn fixed-bottom text-center mt-3">
            <button
              className="btn btn-green"
              onClick={onSave}
              // disabled={answer.length == 0}
            >
              Submit
            </button>
          </div>
        )}
        {validationError && (
          <div className="text-center mt-3">
            <h5 className="text-danger">{validationError}</h5>
          </div>
        )}
      </div>
    </>
  );
}

export default withRouter(Question);
