import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom"; 
import CourseItem from "./CourseItem";
import CoursesService from "../../services/CoursesService";
import "./CourseItem.css";

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [isLogin, setIsLogin] = useState(false);

useEffect(() => {
    CoursesService.getAllCourses()
      .then((response) => response.json())
      .then((data) => {
        // Get all courses
        const allCourses = data?.course?.course;

        // Separate courses into different categories
        const programmingCourses = allCourses.filter(course =>
          /programming/i.test(course.course_title)
        );
        
        const dataStructureCourse = allCourses.filter(course =>
          /data structure and algorithms/i.test(course.course_title)
        );

        const dbmsCourse = allCourses.filter(course =>
          /database management system/i.test(course.course_title)
        );

        // Remaining courses (others)
        const otherCourses = allCourses.filter(course =>
          !/programming/i.test(course.course_title) &&
          !/data structure and algorithms/i.test(course.course_title) &&
          !/database management system/i.test(course.course_title)
        );

        // Concatenate the courses in the desired order
        const sortedCourses = [
          ...programmingCourses, 
          ...dataStructureCourse, 
          ...dbmsCourse, 
          ...otherCourses
        ];

        // Set the sorted courses
        setCourses(sortedCourses);
        setIsLogin(true);
      })
      .catch((err) => {
        // console.error(err);
      });
  }, []);



  const location = useLocation();
  const isNestedRoute =
    location.pathname.startsWith("/course/") && location.pathname !== "/course";


  if (isNestedRoute) {
    return (
      <>
    
        <Outlet />
      </>
    );
  }

  return (
    <div>
      <div className="container mt-lg-5">
        <div className="text-center mt-3 mb-5">
          <h3>Select Your Skill</h3>
        </div>
        <div className="row">
          {courses?.map((cour, index) => (
            <CourseItem key={index} course={cour} />
          ))}
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default Courses;
