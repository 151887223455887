import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import RegisterService from "../../services/RegisterService";

export default function EmailVerify() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const vcode = searchParams.get("code");
  const userId = searchParams.get("userId");
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState(null);

  useEffect(() => {
    verifyEmail();
  }, []);

  const verifyEmail = () => {
    RegisterService.verifyEmail(userId, vcode)
      .then((response) => {
        if (response.status === 200) {
          setVerificationStatus("success");
        } else {
          setVerificationStatus("fail");
        }
      })
      .catch((error) => {
        setVerificationStatus("fail");
      });
  };

  const handleLogin = () => {
    navigate("/login"); 
  };

  return (
    <>
      <div className="text-center mt-5">
        {verificationStatus === "success" ? (
          <>
            <h4>
              Your email has been successfully verified! Thank you for
              confirming your email address.
            </h4>
            <h4>
              Please{" "}
              <u
                onClick={handleLogin}
                className="text-green"
                style={{ cursor: "pointer" }}
              >
                Login here
              </u>{" "}
              to continue.
            </h4>
          </>
        ) : verificationStatus === "fail" ? (
          <h4>Verification Failed</h4>
        ) : (
          <h4>Verifying Email...</h4>
        )}
      </div>
    </>
  );
}
