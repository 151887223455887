import React, { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import withRouter from "../withRouter";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./ViewUserDetails.css";
import RegisterService from "../../services/RegisterService";
import ResumeService, { urls } from "../../services/ResumeService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUserCircle, FaFileAlt, FaUser, FaBan, FaTrashAlt, FaStar, FaTrash } from "react-icons/fa";
import { NavLink } from 'react-router-dom';


function ViewUser({ props }) {
  const [user, setUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const navigate = useNavigate();
  const loginUser = useSelector((state) => state.user.value);
  const [searchError, setSearchError] = useState("");
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [deleteFailed, setDeleteFailed] = useState({});
  const [candidate, setCandidate] = useState(null);
  const [name, setName] = useState("");
  const [uid, setUid] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [edu, setEdu] = useState("");
  const [msg, setMsg] = useState("");
  const [candidateInfo, setCandidateInfo] = useState(null);
  const { userid } = useParams();
  const [isShortlisted, setIsShortlisted] = useState(false);
  const [isHr, setIsHr] = useState(false);




  const fetchProfile = async (userid) => {
    try {
      if (loginUser && userid) {
        const response = await RegisterService.profile(
          userid,
          loginUser.token
        );
        // console.log("my res",response);
        setUid(response?.user?.userid);
        const name = response?.user?.name;
        setName(name);
        const contact = response?.user?.contact;
        setContact(contact);
        const email = response?.user?.email;
        setEmail(email);
        const Education = response?.user?.education;
        setEdu(Education);

        if (response.status) {
          setCandidateInfo(response?.candidateInfo);
          // console.log(candidateInfo?.person);
        } else {
          // console.log("Profile not found");
        }
      } else {
      }
    } catch (error) {
      // console.error("Error fetching profile:", error);
    }
  };

  useEffect(() => {

    fetchProfile();
  }, [loginUser?.token && userid]);


  


  const delUser = (userid) => {
    // console.log(userid);
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmDelete) {
      toast.dismiss();
      setIsLoading(true);
      AdminService.dleteUser(userid, loginUser.token)
        .then((response) => {
          if (response.ok) {
            setUser((prevUsers) =>
              prevUsers.filter((user) => user.userid !== userid)
            );
            setIsDataUpdated(!isDataUpdated);

          } else if (response.status === false || response.status === 500) {
            // console.log("Failed to delete user.");
            toast.error("You can not delete this user.");

          } else {
            // console.error("Failed to delete user.");
          }
          setIsLoading(false);
        })
        .catch((error) => {
          // console.error("Error deleting user:", error);

          setIsLoading(false);
        });
    }
  };

  const result = (userid) => {
    navigate("/usertest/" + userid);
  };

  const blockUser = (userid) => {
    const confirmBlock = window.confirm(
      "Are you sure you want to block this user?"
    );
    if (confirmBlock) {
      toast.dismiss();
      setIsLoading(true);
      const updatedRole = {"isactive": 0};
      AdminService.updateUserRole(updatedRole,userid,  loginUser.token)
        .then((response) => response.json())
        .then((data) => {
          const blockedUser = user.find((user) => user.userid === userid);
          blockedUser.isactive = 0;
          setIsDataUpdated(!isDataUpdated);
          toast.success("User blocked successfully!");
          navigate("/block");
          setIsLoading(false);
        });
    }
  };

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchUsers();
  }, [isDataUpdated]);

  const fetchUsers = () => {
    setIsLoading(true);
    AdminService.getAllUser(loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        if (data?.user?.user) {
          const sortedUsers = data?.user?.user.sort(
            (a, b) => b?.userid - a?.userid
          );
 const filteredUsers = isHr
 ? sortedUsers.filter((user) => user.role === 'hr')
 : sortedUsers;

setUser(filteredUsers);
// console.log(isHr ? 'HR users' : 'All users', filteredUsers);
setIsLoading(false);

        } else {
          setUser([]);
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setUser([]);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchUsers();
  }, [isHr]);

  const handleHRClick = () => {
    setIsHr(true); 
  
  };

  const handleSearch = () => {

    if (!searchValue) {
      setShowValidationMessage(true);
      setSearchError("Please enter a valid name or email.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[a-zA-Z ]+$/;
    if (nameRegex.test(searchValue)) {
      setSearchError("");
    }
    else if (!emailRegex.test(searchValue)) {
      setShowValidationMessage(true);
      setSearchError("Please enter a valid email.");
      return;
    }
    setSearchError("");

    setIsLoading(false);
    toast.dismiss();
    AdminService.searchUser(searchValue, loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data && data?.user && data?.user?.user) {
          setUser(data?.user?.user);
        } else if (data.status == false) {
          // setUser([]);
          toast.error("No matching records.");
          fetchUsers();
        }
        setCurrentPage(1);
        setIsLoading(false);
      })
      .catch((error) => {
        // console.error("Error searching users:", error);
        setUser([]);
        setIsLoading(false);
      });
  };

  const handleCancelSearch = () => {
    setSearchValue("");
    setIsLoading(true);
    fetchUsers();

  };



  const handleNextPage = () => {
    if (indexOfLastItem < filteredUsers.length) {
      setCurrentPage(currentPage + 1);
    }
  };
  
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setSearchError("");
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
    const range = 2; 
    const startPage = Math.max(2, currentPage - range);
    const endPage = Math.min(totalPages - 1, currentPage + range);

    const pageNumbers = [];
    pageNumbers.push(1);

    if (startPage > 2) {
      pageNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      pageNumbers.push("...");
    }

    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // const filteredUsers = user.filter(
  //   (r) => r.role === "student" || r.isactive !== 0
  // );
  const filteredUsers = user.filter(
    (r) =>  r.isactive !== 0
  );


  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const logindata = useSelector((state) => state.user.value);

  const handleButtonClick = async () => {
    const candidateUserId = candidateInfo?.person;

    if (!candidateUserId) {
      toast.error("No Resume Found"); 
      return; 
    }

    try {
    
      toast.dismiss();
      setIsLoading(true)

      const fetchUrl = `${urls.Candidate_Details}/${candidateUserId}`;
      const response = await ResumeService.GetApiCallWithToken(
        fetchUrl,
        logindata.token
      );

      const candidateInfo = response?.data?.data;

      if (candidateInfo.candidates && candidateInfo.candidates.length === 0) {
        alert("No Resume Found");
 
      } else if (response?.data?.status) {
        // console.log("Candidate Info:", candidateInfo);
        setCandidate(candidateInfo);
        localStorage.setItem("candidate", JSON.stringify(candidateInfo));
        setShowProfileModal(false);
        const modal = document.querySelector(".modal");
        const backdrop = document.querySelector(".modal-backdrop");

        if (modal) {
          modal.classList.remove("show");
          modal.style.display = "none";
        }

        if (backdrop) {
          backdrop.classList.remove("show");
          document.body.classList.remove("modal-open"); 
          backdrop.remove(); 
        }
        document.body.style.overflow = ""; 

        if (candidateInfo.candidates[0].template === "use1") {
          navigate("/resumehome/use1");
        } else if (candidateInfo.candidates[0].template === "use2") {
          navigate("/resumehome/use2");
        } else if (candidateInfo.candidates[0].template === "use3") {
          navigate("/resumehome/use3");
        } else if (candidateInfo.candidates[0].template === "use4") {
          navigate("/resumehome/use4");
        } else if (candidateInfo.candidates[0].template === "use5") {
          navigate("/resumehome/use5");
        } else if (candidateInfo.candidates[0].template === "use6") {
          navigate("/resumehome/use6");
        } else if (candidateInfo.candidates[0].template === "use7") {
          navigate("/resumehome/use7");
        } else if (candidateInfo.candidates[0].template === "use8") {
          navigate("/resumehome/use8");
        } else if (candidateInfo.candidates[0].template === "use9") {
          navigate("/resumehome/use9");
        }
      }
    } catch (error) {
      setMsg("Error fetching candidate data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const handleViewProfile = (candidate) => {
    setSelectedCandidate(candidate); 
  };


  const updateShortlisted = async (userid, candida) => {
    // console.log("first short", candida?.shortlitsed)
    let updatedStatus

    const confm = window.confirm("are you want to shortlist this candidate ?")
    if (confm) {
      if (!candida) {
        toast.error("No information found for this user.")
       } else {
        if (candida?.shortlitsed == true) {
          updatedStatus = { "shortlisted": false };
        } else if (candida?.shortlitsed == null || candida?.shortlitsed == undefined) {

          updatedStatus = { "shortlisted": true };
        } else {
          updatedStatus = { "shortlisted": true };

        }
      }
      // console.log("update status", updatedStatus)
      try {
        // console.log(`Updating shortlisted status for userId: ${userid}`);


        const api = `${urls.Update_ShortListed}/${userid}`;
        // console.log("API URL:", api);

        const res = await ResumeService.PutApiCallWithJson(api, updatedStatus, loginUser.token);

        if (res.status) {
          // console.log("Successfully updated shortlisted status");
          toast.success("Successfully updated shortlisted status")
          fetchUsers()
        } else if (res.status === false) {
          toast.error("No information found for this user.")
        }
        else {

          // console.error("Error response from API:", res);

        }

      } catch (error) {
   

      }
    }

    else {

    }
  }




  return (
    <>


      <div className="modal fade"
        id="profileModal"
        tabIndex="-1"
        aria-labelledby="profileModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="profileModalLabel">
                Candidate Profile
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="candinfo">
                {name && (
                  <p>
                    <strong>Name:</strong> {name}
                  </p>
                )}
                {email && (
                  <p>
                    <strong>Email:</strong> {email}
                  </p>
                )}
                {contact && (
                  <p>
                    <strong>Contact:</strong> {contact}
                  </p>
                )}
                {edu && (
                  <p>
                    <strong>Education:</strong> {edu}
                  </p>
                )}
                {candidateInfo?.address && (
                  <p>
                    <strong>Address:</strong> {candidateInfo?.address}
                  </p>
                )}
              </div>

              <div className="d-flex justify-content-center m-2 about-image">
                {isLoading ? (
                <div className="text-center">
                <span> Loading</span>{" "}
             <span
               className="spinner-border spinner-border-sm"
               role="status"
               aria-hidden="true"
             ></span>
          
           </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-green"
                    onClick={handleButtonClick}
                  >
                    View Resume
                  </button>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="container  ">
        <ToastContainer position="top-center" autoClose={2000} className="responsive-toast" />
        <div className="form-group row">
          
        <div className="col-sm-3 col-5 col-lg-2 col-md-2 position-relative2 order-2 order-lg-1 order-md-1">
  <input
    type="text"
    className={`form-control ${searchError ? "is-invalid" : ""}`}
    placeholder="Name or Email"
    value={searchValue}
    onChange={handleSearchChange}
  />
  {searchValue && (
    <span
      className={`clear-input-icon2 ${searchError ? "move-icon2" : ""}`}
      onClick={handleCancelSearch}
    >
      <i className="fa fa-times" />
    </span>
  )}
  {searchError && (
    <small className="text-danger position-absolute" style={{ top: '100%'}}>
      {searchError}
    </small>
  )}
</div>

          <div className="col-sm-3 col-2 col-lg-3 col-md-3  p-sm-1 p-lg-1 p-1 p-md-1 order-2 order-sm-2 order-lg-1 order-md-1 me-2 me-sm-1 me-lg-0 me-md-0">
            <button
              className="btn btn-md btn-green button-spacing  "
              onClick={handleSearch}
            >
              Search
            </button>
          </div>

          <div className="col-sm-12 col-12 col-lg-5 col-md-5 ul align-content-center order-1 order-sm-2 order-lg-2 order-md-2">
            <h4 className="test-md-start text-center text-lg-start text-sm-center">Users List</h4>
          </div>
 
  <div className="col-lg-2 col-2 col-md-2 col-sm-2 d-flex justify-content-md-end justify-content-lg-end align-items-center order-3">
      <div className="dropdown">
        <button
          className="btn btn-md btn-green dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          All Users
        </button>
        <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton">
          <li>
            <NavLink 
             className={({ isActive }) =>
              isActive ? "dropdown-item active" : "dropdown-item"
            } to="/hrlist" activeClassName="active">
              HR
            </NavLink>
          </li>
          <li>
            <NavLink className="dropdown-item " to="/shortlist" activeClassName="active">
              Shortlisted
            </NavLink>
          </li>
          <li>
            <NavLink className="dropdown-item " to="/block" activeClassName="active">
              Blocked
            </NavLink>
          </li>
        </ul>
      </div>
 
    </div>

        </div>
        
        {isLoading ? (
          <div className="text-center">
               <span> Loading</span>{" "}
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
         
          </div>
        ) : (
          <>
            {currentItems.some((o) => o.isactive !== 0) && (

              <div className="table-responsive mt-5">
                <table className="table table-bordered text-center">
                  <thead>
                    <tr className="text-center">
                      <th>S.no</th>
                      <th>Name</th>
                      <th>Education</th>
                      <th>Contact</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems
                      .filter((o) => o.isactive !== 0)
                      .map((ob, index) => (
                        <tr key={index}>
                          <td>{indexOfFirstItem + index + 1}</td>
                          <td className="profile-text justify-content-center"
                            data-bs-toggle="modal"
                            data-bs-target="#profileModal"
                            onClick={() => {
                              fetchProfile(ob.userid);
                            }}
                            style={{
                              color: '#1ABC9C',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}>{ob.name}  </td>
                          <td>{ob.education}</td>
                          <td>{ob.contact}</td>
                          <td>{ob.email}</td>
                          <td>{ob.role}</td>

                          <td>
                            <div className="dropdown">
                              <button
                                className="btn btn-light dropdown-toggle"
                                type="button"
                                id={`dropdownMenuButton${index}`}
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  padding: 0,
                                }}
                                onClick={() => {
                                  fetchProfile(ob.userid);
                                }}
                              >
                                Action
                                <i className="bi bi-three-dots"></i>
                              </button>
                              <ul
                                className="dropdown-menu dropdown-menu-end text-center"
                                aria-labelledby={`dropdownMenuButton${index}`}
                              >
                                <li className="text-center py-2">
                                  <Link className="profile-link">
                                    <div
                                      className="profile-container"
                                      data-bs-toggle="modal"
                                      data-bs-target="#profileModal"
                                      style={{ cursor: "pointer" }}
                                    >

                                      <FaUserCircle
                                        size={35}
                                        color="#ccc"
                                        className="default-profile-icon"
                                      />

                                    </div>
                                    <div>
                                      <h5
                                        className="profile-text justify-content-center"
                                        data-bs-toggle="modal"
                                        data-bs-target="#profileModal"
                                      >

                                        {ob.name || "User Name"}
                                      </h5>{" "}
                                    </div>
                                  </Link>

                                </li>
                                <li><hr className="dropdown-divider" /></li>
                                <li className="my-1">
                                  <button
                                    className="dropdown-item d-flex align-items-center  btn-sm text-center"
                                    onClick={() => result(ob.userid)}
                                    style={{
                                      backgroundColor: "#28a745",
                                      color: "white",
                                      borderRadius: "10px",
                                      width: "90%",
                                      margin: "auto",

                                    }}
                                  >
                                    <FaFileAlt className="me-2" /> Test History
                                  </button>
                                </li>

                                <li className="my-1">
                                  <button
                                    className="dropdown-item d-flex align-items-center  btn-sm text-center"
                                    onClick={() => blockUser(ob.userid)}
                                    style={{
                                      backgroundColor: "#6c757d",
                                      color: "white",
                                      borderRadius: "10px",
                                      width: "90%",
                                      margin: "auto",
                                    }}
                                  >
                                    <FaBan className="me-2" /> Block
                                  </button>
                                </li>
                                <li className="my-1">
                                  <button
                                    className="dropdown-item d-flex align-items-center btn-sm text-center"
                                    onClick={() => delUser(ob.userid)}
                                    style={{
                                      backgroundColor: "#dc3545",
                                      color: "white",
                                      borderRadius: "10px",
                                      width: "90%",
                                      margin: "auto",
                                    }}
                                  >
                                    <FaTrashAlt className="me-2" /> Delete
                                  </button>
                                </li>

                                <li className="my-1">
                                  <button
                                    className="dropdown-item d-flex align-items-center btn-sm"
                                    onClick={() => {
                                      // console.log('Shortlist value on button click:', ob?.Candidateinfo?.shortlisted);

                                      updateShortlisted(ob.userid, ob?.Candidateinfo);
                                    }}
                                    style={{
                                      backgroundColor: "#1ABC9C",
                                      color: "white",
                                      borderRadius: "10px",
                                      width: "90%",
                                      margin: "auto",
                                    }}
                                  >
                                    <FaStar className="me-2" />
                                    {ob?.Candidateinfo?.shortlisted == true ? "UnShortlist" : "Shortlist"}
                                  </button>
                                </li>

                              </ul>
                            </div>
                          </td>

                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}



   {/* Pagination Controls */}
   {filteredUsers.length > itemsPerPage && (
      <div className="container d-flex justify-content-center pagination-container">
        <button
          type="button"
          className="btn btn-md btn-dark me-1 me-sm-1"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          &larr; Previous
        </button>
        <div>
          {getPageNumbers().map((pageNumber) => (
            <button
              key={pageNumber}
              type="button"
              className={`btn btn-md btn-dark  m-1 ${currentPage === pageNumber ? "active" : ""}`}
              onClick={() => handlePageClick(pageNumber)}
            >
              {pageNumber}
            </button>
          ))}
        </div>
        <button
          type="button"
          className="btn btn-md btn-dark"
          onClick={handleNextPage}
          disabled={indexOfLastItem >= filteredUsers.length}
        >
          Next &rarr;
        </button>
      </div>
    )}
          </>
        )}
      </div>
    </>
  );
}

export default withRouter(ViewUser);
