import React from "react";
import AdminService from "../../services/AdminService";
import CoursesService from "../../services/CoursesService";
import { useEffect, useState, useRef } from "react";
import withRouter from "../withRouter";
import ModulesService from "../../services/ModulesService";
import CourseItem from "../course/CourseItem";
import "./Admin.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

function SaveCourseModules(props) {
  const [getmodule, setGetModule] = useState([]);
  const [getcourse, setGetCourse] = useState([]);
  const [getcoursemodule, setGetCourseModule] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [msg, setMsg] = useState("");
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [selectedCM, setSelectedCM] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");
  const [moduleTitle, setModuleTitle] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const loginUser = useSelector((state) => state.user.value);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
     const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef(null);

  var courseBox = undefined;
  var moduleBox = undefined;
  // console.log(course_title,description,active_status)
  const save = (event) => {
    event.preventDefault();
    var course = selectedCourse ? selectedCourse.value : "";
    var module = selectedModule ? selectedModule.value:"";
    toast.dismiss();
    // console.log(course_title, description, active_status);
    setIsLoading(true)
    var ob = { course, module };
    // console.log(ob);
    AdminService.addCoursesModules(course, module, loginUser?.token)
      .then((response) => response.json())

        .then((data) => { 
          if (data.status === true) {
            toast.success(data?.message || "Course Module Saved Successfully!");
            fetchCourseModules();
                      setIsLoading(false)
                        isLoading(false)


          } else if (data?.status === false) {
            toast.error(data?.message || "Failed to save the course module.");
            fetchCourseModules();
            isLoading(false)
          
          }
        })
        .catch((error) => {
          setIsLoading(false)
         
        });
        
       
        setSelectedCourse(null); 
        setSelectedModule(null);  
        setCourseTitle("");      
        setModuleTitle(""); 
    fetchCourseModules();
    event.target.reset();
  };

  const delcoursemodule = (course_module_id) => {
    // console.log("sv", course_module_id)
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this course with module?"
    );
 
    
  };

  const updateCMData = (event) => {
    event.preventDefault();
    if (selectedCM) {
      var updatedCM = {
        course_module_id: selectedCM?.course_module_id,
        course: courseTitle,
        module: moduleTitle,
      };
      toast.dismiss();
      // console.log(updatedCM);
      AdminService.updateCourseModule(selectedCM?.course_module_id, updatedCM)
        .then((response) => response.json())
        .then((data) => {
          // console.log("Update course module", data);
          // setMsg("Course Module Updated..");
          toast.success("Course Module Updated..");
          // Update the course directly in the state
          setGetCourseModule((prevCourseList) =>
            prevCourseList.map((coursemodule) =>
              coursemodule.course_module_id === selectedCM.course_module_id
                ? data
                : coursemodule
            )
          );
          setIsDataUpdated(!isDataUpdated); // Trigger data update

          setSelectedCM(null);
          setCourseTitle("");
          setModuleTitle("");

          data = {
            ...selectedCM,
            course: courseTitle,
            module: moduleTitle,
          };

          // console.log(data);
        })
        .catch((error) => {
          // setMsg("Update failed..");
          // toast.error("Update failed..");
        });
    }
  };

  const cancelUpdate = () => {
    setSelectedCM(null);
    setCourseTitle("");
    setModuleTitle("");
    setIsUpdating(false);
  };

  const upCourse = (coursemodule) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
    setSelectedCM(coursemodule);

    setCourseTitle(coursemodule?.course_id);

    setModuleTitle(coursemodule?.module_id);
    // console.log("Module ID:", coursemodule.module_id);
    setIsUpdating(true);

  };



  useEffect(() => {
    if (isDataUpdated) {
      setMsg("");
      setSelectedCM(null);
      setCourseTitle("");
      setModuleTitle("");
      setIsUpdating(false);
    }
  }, [isDataUpdated]);

  const fetchCourseModules = () => {
    setIsLoading(true)
    CoursesService.getAllCoursesModule(loginUser?.token)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data?.courses_modules);
        setGetCourseModule(data?.courses_modules?.course_modules);
      })
      .catch((error) => {
        setIsLoading(false)
        // console.error("Error fetching course modules:", error);
      });
  };

  useEffect(() => {
    setIsLoading(true)
    CoursesService.getAllCourses(loginUser?.token)
      .then((response) => response.json())
      .then((data) => {
        const courses = data?.course?.course.map((course) => ({
            value: course?.course_id,
            label: course?.course_title,
        }));
    
        // console.log(data.course);
        setGetCourse(courses);
        setIsLoading(false);
    })
    .catch((error) => {
        setIsLoading(false);
    });
    

    ModulesService.getAllModules(loginUser?.token)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data?.module);
    
        const modules = data?.module?.module?.map((module) => ({
            value: module?.module_id,
            label: module?.module_title,
        }));
        // setGetModule(data?.module?.module);
        setGetModule(modules);
    })
    .catch((error) => {
        // console.error("Error fetching modules:", error);
    });
    

    fetchCourseModules();
  }, [isDataUpdated]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getcoursemodule?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const totalPages = Math.ceil(getcoursemodule?.length / itemsPerPage);
    const range = 2; 
    const startPage = Math.max(2, currentPage - range);
    const endPage = Math.min(totalPages - 1, currentPage + range); 

    const pageNumbers = [];

    pageNumbers.push(1);

    if (startPage > 2) {
      pageNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }


    if (endPage < totalPages - 1) {
      pageNumbers.push("...");
    }

    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const handleCourseChange = (selectedOption) => {
    setSelectedCourse(selectedOption);
  };
  const handleModuleChange = (selectedOption) =>{
    setSelectedModule(selectedOption);
  };


  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className="responsive-toast"
      />
      <div className="container" ref={formRef}>
        <div className="row d-flex align-items-center justify-content-between mb-5">
          <div className="col-lg-2 col-2 col-md-2 col-sm-2">
            <button
              className="btn bk-adm back-button "
              onClick={() => navigate("/user")}
            >
              Back
            </button>
          </div>

          <div className="col-lg-8 col-8 col-md-8 col-sm-8 text-center">
            <h4 className="mb-0">New Skill Module Association</h4>
          </div>

          <div className="col-lg-2 col-2 col-md-2 col-sm-2 text-end">
            {/* You can add another button or content here if needed */}
          </div>
        </div>

        <form onSubmit={isUpdating ? updateCMData : save}>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-5 col-sm-6 col-6">
           
              <Select
            value={selectedCourse}
            onChange={handleCourseChange
            }
            options={getcourse}
            isSearchable
            placeholder="Select a Course..."
            isClearable
            required
          />

            </div>

            <div className="col-lg-4 col-md-5 col-sm-6 col-6">
            <Select
            value={selectedModule}
            onChange={handleModuleChange
            }
            options={getmodule}
            isSearchable
            placeholder="Select a Module..."
            isClearable
            required
          />

            </div>


            
          </div>

          {/* New row for the button */}
          <div className="row col-lg-12 d-flex justify-content-center mt-2">
            <div className="col-lg-6 d-flex justify-content-center">
              {isUpdating && selectedCM ? (
                <>
                  <button
                    type="submit"
                    className="btn btn-md btn-green"
                    style={{ borderRadius: "10px" }}
                  >
                    {isUpdating ? "Update" : "Save"}
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-md btn-secondary"
                    style={{ borderRadius: "10px" }}
                    onClick={cancelUpdate}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  type="submit"
                  className="btn btn-md btn-green"
                  style={{ borderRadius: "10px" }}
                >
                  Add association
                </button>
              )}
            </div>
          </div>
          <br></br>
          <div>
            &nbsp;<b className="text-success">{msg}</b>
          </div>
        </form>
      </div>
      <br />
      {isLoading ? (
             <div className="text-center">
             <span> Loading</span>{" "}
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
       
        </div>
        ) : (
          
      <div className="container">
        
        <div className="table-responsive">
        {currentItems?.length > 0 && (
          <table className="table table-bordered  ">
            <thead>
              <tr className="text-center ">
                <th>S.no</th>
                <th>Skill </th>
                <th>Modules</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((ob, index) => (
                <tr key={index} className="text-center ">
                  <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                  <td>{ob?.course_title}</td>
                  <td>{ob?.module_title}</td>
                  <td className="d-flex justify-content-center">
                    {/* <button
                      className="btn btn-sm btn-secondary"
                      style={{ borderRadius: "10px" }}
                      onClick={() => upCourse(ob)}
                    >
                      Update
                    </button> */}
                    &nbsp;
                    <button
                      className="btn btn-md btn-danger"
                      style={{ borderRadius: "10px" }}
                      onClick={() => delcoursemodule(ob?.course_module_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        </div>
        {currentItems?.length > 0 && (
        <div className="container justify-content-center d-flex pagination-container">
          {/* Previous Button */}
          <button
            type="button"
            className="btn btn-md btn-dark"
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          >
            &larr; Previous
          </button>

   
          <div>
            {getPageNumbers()?.map((pageNumber, index) => (
              <button
                key={index}
                type="button"
                className={`btn btn-md btn-dark m-1 ${
                  currentPage === pageNumber ? "active" : ""
                }`}
                onClick={() => {
                  if (pageNumber !== "...") handlePageClick(pageNumber);
                }}
                disabled={pageNumber === "..."}
              >
                {pageNumber}
              </button>
            ))}
          </div>

          {/* Next Button */}
          <button
            type="button"
            className="btn btn-md btn-dark"
            onClick={goToNextPage}
            disabled={
              currentPage === Math.ceil(getcoursemodule?.length / itemsPerPage)
            }
          >
            Next &rarr;
          </button>
        </div>
        )}
      </div>
        )}
    </>
  );
}
export default withRouter(SaveCourseModules);



