import { createSlice } from "@reduxjs/toolkit";

export const courseSlice = createSlice({
  name: "course",
  initialState: {
    courseList: [], // Changed the initial state structure to include courseList
    selectedCourse: null,
    courseTitle: {},
  },
  reducers: {
    updateCourse: (state, action) => {
      const { courseId, updatedCourse } = action.payload;
      const updatedList = state.courseList.map((course) =>
        course.course_id === courseId ? updatedCourse : course
      );
      state.courseList = updatedList;
      state.selectedCourse = updatedCourse;
    },

    courseTitle(state, action) {
      state.courseTitle = action.payload.course_title; // Correctly update state
    },
  
  },
});

export const { updateCourse, courseTitle } = courseSlice.actions;

export default courseSlice.reducer;
