let networkStatus = {
  isOnline: navigator.onLine, 
};

export const setNetworkStatus = (status) => {
  networkStatus.isOnline = status;
};

export const getNetworkStatus = () => {
  return networkStatus.isOnline;
};
