const SERVER = process.env.REACT_APP_LIVE_SKILLEDFRESHER_SERVER;

class QuestionsService {
  constructor() {
    this.token = localStorage.getItem("token");
  }

  getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  getQuestionById(quesLevel, moduleId, userid, token) {
    let params = {
      quesLevel: parseInt(quesLevel),
      moduleId: parseInt(moduleId),
      userid: userid,
    };
    return fetch(`${SERVER}/api/question/getQuestionById`, {
      method: "POST",
      // headers: this.getHeaders(),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    });
  }

  getAllQuestionById(course_id, quesLevel, moduleId, token) {
    let params = {
      course_id: parseInt(course_id),
      quesLevel: parseInt(quesLevel),
      moduleId: parseInt(moduleId),
    };
    return fetch(`${SERVER}/api/question/getAllQuestionById`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    });
  }

  questionByModule(quesLevel, moduleId, token) {
    let params = {
      quesLevel: parseInt(quesLevel),
      moduleId: parseInt(moduleId),
    };
    return fetch(`${SERVER}/api/question/questionbymodule`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    });
  }

  getAllQuestion(start, token) {
    return fetch(`${SERVER}/api/question/list/` + start, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new QuestionsService();
