import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { changeData } from "../reduxdata/UserSlice";
import {useEffect, useState } from "react";
import LoginService from "../services/LoginService";
import { jwtDecode } from "jwt-decode";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./Menu.css";
import { Nav } from "react-bootstrap";

export default function Menu() {
  const location = useLocation();
  const loginUser = useSelector((state) => state.user.value);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const resumeData = useSelector((state) => state.user.resumeVal);
  const d = localStorage.getItem("resumeData");

  const [loginCheck, setLoginCheck] = useState(false);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const savedProfile = JSON.parse(localStorage.getItem("userProfile"));
  const username = savedProfile ? savedProfile.username : loginUser?.username;

  const logout = () => {
    dispatch(
      changeData({
        username: undefined,
        token: undefined,
        islogin: false,
        role: undefined,
      })
    );
    setLoginCheck(true);
    localStorage.removeItem("resumeData");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("CandidateInfo");
    localStorage.removeItem("userProfile");

  };

  if (loginCheck) {
    setLoginCheck(false);
    return <Navigate to="/login" />;
  } else
    return (
      <>
        <div>
          <Link
            to=""
            className="close-navbar-toggler collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav" 
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          ></Link>

          <nav className="navbar navbar-expand-sm navbar-light">
            <Link className="navbar-brand header" to="/">
              <div className="container d-flex align-items-center px-0">
                <div className="row mr-5">
                  <div className="col logo">
                    <img src="/images/skilledfresher.png" alt="" />
                  </div>
                  <div className="col text-center px-0 mx-1">
                    <h1 className="logo mr-auto pb-0 mb-0 text-left">
                      SkilledFresher.<span>in</span>
                    </h1>
                    <p className="logo-text">stand up for what you know</p>
                  </div>
                </div>
              </div>
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse" 
              data-bs-target="#navbarNav" 
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
              <span className="navbar-toggler-icon" />
              <span className="navbar-toggler-icon" />
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mx-auto nav-pills">
                {loginUser.islogin ? (
                  <>
            
                    {loginUser.role == "admin" ? (
                      <>
                        <li className="nav-item ms-2">
                          <div className="dropdown">
                            <Link
                              className={`btn dropdown-toggle nav-link ${
                                [
                                  "/course/saveCourse",
                                  "/course/saveModules",
                                  "/course/saveCourseModules",
                                ].includes(window.location.pathname)
                                  ? "active"
                                  : ""
                              }`}
                              to="#"
                              id="dropdownMenuLink-1"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Skills
                            </Link>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLink-1"
                            >
                              <li>
                                <NavLink
                                  to="/course/saveCourse"
                                  className={({ isActive }) =>
                                    isActive
                                      ? "nav-link dropdown-item active"
                                      : "nav-link dropdown-item"
                                  }
                                  onClick={() =>
                                    document
                                      .getElementById("dropdownMenuLink-1")
                                      .click()
                                  }
                                >
                                  Skills
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/course/saveModules"
                                  className={({ isActive }) =>
                                    isActive
                                      ? "nav-link dropdown-item active"
                                      : "nav-link dropdown-item"
                                  }
                                  onClick={() =>
                                    document
                                      .getElementById("dropdownMenuLink-1")
                                      .click()
                                  }
                                >
                                  Modules
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/course/saveCourseModules"
                                  className={({ isActive }) =>
                                    isActive
                                      ? "nav-link dropdown-item active"
                                      : "nav-link dropdown-item"
                                  }
                                  onClick={() =>
                                    document
                                      .getElementById("dropdownMenuLink-1")
                                      .click()
                                  }
                                >
                                  Skill-Modules
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </li>

                      
                        <li className="nav-item">
                          <div className="dropdown">
                            <Link
                              className={`btn dropdown-toggle nav-link ${
                                [
                                  "/course/saveQuestions",
                                  "/course/questionsList",
                                ].includes(window.location.pathname)
                                  ? "active"
                                  : ""
                              }`}
                              to="#"
                              id="dropdownMenuLink2"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Questions
                            </Link>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLink2"
                            >
                              <li>
                                <NavLink
                                  className={({ isActive }) =>
                                    isActive
                                      ? "nav-link dropdown-item active"
                                      : "nav-link dropdown-item"
                                  }
                                  to="/course/saveQuestions"
                                  onClick={() =>
                                    document
                                      .getElementById("dropdownMenuLink2")
                                      .click()
                                  }
                                >
                                  Add New
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  className={({ isActive }) =>
                                    isActive
                                      ? "nav-link dropdown-item active"
                                      : "nav-link dropdown-item"
                                  }
                                  to="/course/questionsList"
                                  onClick={() =>
                                    document
                                      .getElementById("dropdownMenuLink2")
                                      .click()
                                  }
                                >
                                  All Questions
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li className="nav-item">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "nav-link active" : "nav-link"
                            }
                            to="/user"
                          >
                            Users
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "nav-link active" : "nav-link"
                            }
                            to="/course/addhr"
                          >
                            Add Employee
                          </NavLink>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}

                    {loginUser.role == "student" ? (
                      <>
                        <li className="nav-item text-nowrap">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "nav-link active" : "nav-link"
                            }
                            to="/course"
                          >
                            My Skills
                          </NavLink>
                        </li>
                        <li className="nav-item text-nowrap">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "nav-link active" : "nav-link"
                            }
                            to="/resumehome"
                          >
                            <b
                              style={{ cursor: "pointer" }}
                            >
                              My Resume
                            </b>
                          </NavLink>
                        </li>

                        <li className="nav-item text-nowrap">
                          <NavLink
                            to="/testhistory"
                            className={({ isActive }) =>
                              isActive ? "nav-link active" : "nav-link"
                            }
                          >
                            <b style={{ cursor: "pointer" }}>Test History</b>
                          </NavLink>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}

                    {loginUser.role == "hr" ? (
                      <>
                        <li className="nav-item text-nowrap">
                          <NavLink
                            to="/studentDetail"
                            className={({ isActive }) =>
                              isActive ? "nav-link active" : "nav-link"
                            }
                          >
                            <b
                              style={{ cursor: "pointer" }}
                              className="nav-link"
                            >
                              Candidates
                            </b>
                          </NavLink>
                        </li>
                        <li className="nav-item text-nowrap">
                          <NavLink
                            to="/hrshortlist"
                            className={({ isActive }) =>
                              isActive ? "nav-link active" : "nav-link"
                            }
                          >
                            <b
                              style={{ cursor: "pointer" }}
                              className="nav-link"
                            >
                           Shortlisted
                            </b>
                          </NavLink>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {loginUser.role == "student" ||
                    loginUser.role == "admin" ||
                    loginUser.role == "hr" ? (
                      <>
                        <li
                          className="nav-item dropdown"
                          style={{ cursor: "pointer", color: "red" }}
                        >
                          <Link
                            to="#"
                            className={`nav-link dropdown-toggle btn ${
                              ["/myProfile", "/changePassword"].includes(
                                window.location.pathname
                              )
                                ? "active"
                                : ""
                            }`}
                            id="userDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="uil uil-cog" />
                            &nbsp;
                            {username}
                          </Link>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="userDropdown"
                          >
                            <li>
                              <NavLink
                                className={({ isActive }) =>
                                  isActive
                                    ? "nav-link dropdown-item active"
                                    : "nav-link dropdown-item"
                                }
                                to="/myProfile"
                                onClick={() =>
                                  document
                                    .getElementById("userDropdown")
                                    .click()
                                }
                              >
                                Profile
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className={({ isActive }) =>
                                  isActive
                                    ? "nav-link dropdown-item active"
                                    : "nav-link dropdown-item"
                                }
                                to="/changePassword"
                                onClick={() =>
                                  document
                                    .getElementById("userDropdown")
                                    .click()
                                }
                              >
                                Change Password
                              </NavLink>
                            </li>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <NavLink
                                className={({ isActive }) =>
                                  isActive
                                    ? "nav-link dropdown-item active"
                                    : "nav-link dropdown-item"
                                }
                                to="/"
                                onClick={() => {
                                  logout();
                                  document
                                    .getElementById("userDropdown")
                                    .click();
                                }}
                              >
                                Logout
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <a className="nav-link" href="http://skilledfresher.in/">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        to="/course"
                      >
                        Skills
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        to="/resumehome"
                      >
                        Resume
                      </NavLink>
                    </li>

                    {/* <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        to="/contact"
                      >
                        Contact
                      </NavLink>
                    </li> */}

                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        to="/register"
                      >
                        Register
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        to="/login"
                      >
                        Login
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            </div>
            {/* </div> */}
          </nav>
        </div>
      </>
    );
}
