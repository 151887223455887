// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.submit-bt {
    text-align: center;
    position: relative;
}

.submit-btns {
    text-align: center;
    position: relative;
}

.lnkbtn {
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;

}

.contact-form label {

    margin-right: 500px!important;

}

.submit-btns {
    background: #474559!important;
    border-radius: 50px !important;
    color: #ffffff !important;
    font-weight: bold !important;
    border: 0!important;
    cursor: pointer;
    transition: all 0.4s ease;
    margin: 0;
    position: relative;
    top: auto;
    text-align: center !important;


}

.lbtn {
    float: left;
}

.rbtn {
    float: right;
}

.submit-bt {
    background: #1ABC9C!important;
    border-radius: 50px!important;
    color: #ffffff!important;
    font-weight: bold!important;
    border: 0;
    cursor: pointer;
    transition: all 0.4s ease;
    margin: 0;
    position: relative;
    top: auto;
    text-align: center!important;


}

.submit-bt:hover {
    background: #474559!important;
}

.submit-btns:hover {
    background: #1ABC9C!important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Login.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,iBAAiB;;AAErB;;AAEA;;IAEI,6BAA6B;;AAEjC;;AAEA;IACI,6BAA6B;IAC7B,8BAA8B;IAC9B,yBAAyB;IACzB,4BAA4B;IAC5B,mBAAmB;IACnB,eAAe;IACf,yBAAyB;IACzB,SAAS;IACT,kBAAkB;IAClB,SAAS;IACT,6BAA6B;;;AAGjC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,6BAA6B;IAC7B,6BAA6B;IAC7B,wBAAwB;IACxB,2BAA2B;IAC3B,SAAS;IACT,eAAe;IACf,yBAAyB;IACzB,SAAS;IACT,kBAAkB;IAClB,SAAS;IACT,4BAA4B;;;AAGhC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":["\r\n.submit-bt {\r\n    text-align: center;\r\n    position: relative;\r\n}\r\n\r\n.submit-btns {\r\n    text-align: center;\r\n    position: relative;\r\n}\r\n\r\n.lnkbtn {\r\n    text-decoration: none;\r\n    color: #ffffff;\r\n    font-weight: bold;\r\n\r\n}\r\n\r\n.contact-form label {\r\n\r\n    margin-right: 500px!important;\r\n\r\n}\r\n\r\n.submit-btns {\r\n    background: #474559!important;\r\n    border-radius: 50px !important;\r\n    color: #ffffff !important;\r\n    font-weight: bold !important;\r\n    border: 0!important;\r\n    cursor: pointer;\r\n    transition: all 0.4s ease;\r\n    margin: 0;\r\n    position: relative;\r\n    top: auto;\r\n    text-align: center !important;\r\n\r\n\r\n}\r\n\r\n.lbtn {\r\n    float: left;\r\n}\r\n\r\n.rbtn {\r\n    float: right;\r\n}\r\n\r\n.submit-bt {\r\n    background: #1ABC9C!important;\r\n    border-radius: 50px!important;\r\n    color: #ffffff!important;\r\n    font-weight: bold!important;\r\n    border: 0;\r\n    cursor: pointer;\r\n    transition: all 0.4s ease;\r\n    margin: 0;\r\n    position: relative;\r\n    top: auto;\r\n    text-align: center!important;\r\n\r\n\r\n}\r\n\r\n.submit-bt:hover {\r\n    background: #474559!important;\r\n}\r\n\r\n.submit-btns:hover {\r\n    background: #1ABC9C!important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
