
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css";

const NetworkStatusContext = createContext();

export const useNetworkStatus = () => useContext(NetworkStatusContext);

export const NetworkStatusProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [networkErrorMessage, setNetworkErrorMessage] = useState(null);

  const updateNetworkErrorMessage = useCallback((message) => {
    setNetworkErrorMessage(message);
  }, []);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      updateNetworkErrorMessage(null);
    };

    const handleOffline = () => {
      setIsOnline(false);
      updateNetworkErrorMessage(
        "You are offline. Please check your internet connection."
      );

    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [updateNetworkErrorMessage]);

  return (
    <NetworkStatusContext.Provider
      value={{ isOnline, networkErrorMessage, updateNetworkErrorMessage }}
    >
      {children}
    </NetworkStatusContext.Provider>
  );
};
