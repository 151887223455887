import React, { useEffect, useState } from "react";
import "./UseTemp2.css";
import ResumeService, { urls } from "../../../services/ResumeService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UseTemp2 = () => {
const SERVER = process.env.REACT_APP_LIVE_SKILLEDFRESHER_SERVER;

  const logindata = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const candidate = JSON.parse(localStorage.getItem("candidate"));

  const downloadPDF = () => {
    const input = document.getElementById("resume-content2"); // Adjust ID if necessary

    html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Scale ratio to fit canvas content within the PDF width
      const scaleRatio = pdfWidth / canvasWidth;
      const scaledHeight = canvasHeight * scaleRatio;

      // Define top and bottom padding in PDF units (e.g., mm)

      const topPadding = 6;
      const bottomPadding = 6;

      // Adjust available page height to account for top and bottom padding
      const adjustedPageHeight = pdfHeight - topPadding - bottomPadding;

      // Break down content into multiple pages if needed
      let yOffset = 0;
      while (yOffset < canvasHeight) {
        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = canvas.width;
        pageCanvas.height = Math.min(
          canvasHeight - yOffset,
          adjustedPageHeight / scaleRatio
        );

        const context = pageCanvas.getContext("2d");
        context.drawImage(
          canvas,
          0,
          yOffset,
          canvas.width,
          pageCanvas.height,
          0,
          0,
          canvas.width,
          pageCanvas.height
        );

        const pageData = pageCanvas.toDataURL("image/png");

        // Add content to the PDF with top padding on each page
        pdf.addImage(
          pageData,
          "PNG",
          0,
          yOffset === 0 ? 0 : topPadding, // No top padding for the first page
          pdfWidth,
          (pageCanvas.height * pdfWidth) / canvas.width
        );

        yOffset += pageCanvas.height;

        if (yOffset < canvasHeight) pdf.addPage(); // Add a new page if more content remains
      }

      pdf.save("profile.pdf");
    });
  };

  return (
    <>
    
      <div className="container tempcon2 my-4">
        <div className="button-container2 row">
          <div className="col-12  col-sm-12 col-md-6 ">
            <button
              className="btn bk2 back-button"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6 ">
            <button className="btn db2 download-button" onClick={downloadPDF}>
              Download as PDF
            </button>
          </div>
        </div>
        <div className="scroll-container2">
          <div id="resume-content2" className="my-4">
            <div className="uset2">
              <div className="top-section2">
                <img
                  src={
                    `${SERVER}/upload/user_image/${candidate?.candidates[0]?.image}` ||
                    "/path/to/default-image.png"
                  }
                  className="Profile_img2"
                />
                <p className="n1">{candidate?.name}</p>
              </div>
              <div className="clearfix"></div>
              <div>
                <div className="sleft2">
                  <div className="contact2">
                    <p className="heading2">Contact</p>
                    <div className="pp">
                      <p className="p2 d-flex">
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="icon"
                        />
                        <span className="span22">
                          {candidate?.candidates[0]?.address}
                        </span>
                      </p>
                      <p className="p2">
                        {/* <img src="" alt="Call" className="img" /> */}
                        <FontAwesomeIcon icon={faPhone} className="icon" />
                        <span className="span22">{candidate?.contact}</span>
                      </p>
                      <p className="p2">
                        {/* <img src="" alt="Mail" className="gml" /> */}
                        <FontAwesomeIcon icon={faEnvelope} className="icon" />
                        <span className="span22">{candidate?.email}</span>
                      </p>
                      {candidate?.candidates[0]?.linkedin_profile && (
                        <p className="p2 d-flex text-justify">
                          {/* <img src="" alt="LinkedIn" className="img" /> */}
                          <FontAwesomeIcon icon={faLinkedin} className="icon" />
                          <span className="span22">
                            {candidate?.candidates[0]?.linkedin_profile}
                          </span>
                        </p>
                      )}
                      {candidate?.candidates[0]?.github_profile && (
                        <p className="p2 d-flex ">
                      
                          <FontAwesomeIcon icon={faGithub} className="icon" />
                          <span className="span22">
                            {candidate?.candidates[0]?.github_profile}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="pi">
                    <p className="heading2">Personal Info</p>
                    <div className="pp">
                      {candidate?.candidates[0]?.father_name && (
                        <p className="p2">
                          <b> Father Name -</b>{" "}
                          <span className="span22">
                            {candidate?.candidates[0]?.father_name}
                          </span>
                        </p>
                      )}
                      {candidate?.candidates[0]?.date_of_birth && (
                        <p className="p2">
                          <b> Date of Birth -</b>{" "}
                          <span className="span22">
                            {candidate?.candidates[0]?.date_of_birth}
                          </span>
                        </p>
                      )}
                      {candidate?.candidates[0]?.gender && (
                        <p className="p2">
                          <b> Gender -</b>{" "}
                          <span className="span22">
                            {candidate?.candidates[0]?.gender}
                          </span>
                        </p>
                      )}
                      {candidate?.candidates[0]?.marital_status && (
                        <p className="p2">
                          <b> Marital Status -</b>{" "}
                          <span className="span22">
                            {candidate?.candidates[0]?.marital_status}
                          </span>
                        </p>
                      )}
                      {candidate?.candidates[0]?.nationality && (
                        <p className="p2">
                          <b>Nationality -</b>{" "}
                          <span className="span22">
                            {candidate?.candidates[0]?.nationality}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                  {candidate?.candidates[0]?.skills && (
                    <div className="skills">
                      <p className="heading2">Skills</p>
                      <div className="pp">
                        <ul>
                          <li className="p2">
                            <span className="span22">
                              {candidate?.candidates[0]?.skills}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  {candidate?.candidates[0]?.language_known && (
                    <div className="language">
                      <p className="heading2">Language Known</p>
                      <div className="pp">
                        <p className="p2">
                          <span className="span22">
                            {candidate?.candidates[0]?.language_known}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {candidate?.candidates[0]?.interest && (
                    <div className="interest">
                      <p className="heading2">Interest</p>
                      <div className="pp">
                        <p className="p2">
                          <span className="span22">
                            {candidate?.candidates[0]?.interest}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {candidate?.project?.length > 0 && (
                    <div className="projects">
                      <p className="heading2">Projects</p>
                      <div className="pp">
                        {candidate?.project.map((project, index) => (
                          <div key={index}>
                            <p className="p22">
                              <b className="bold me-1 title2">Project Title:</b>
                              <b className="jobtitle2 projectTitle">{project?.project_title}</b>
                            </p>
                            <p className="p2">
                              <b className="bold me-1">Description:</b>
                              {project?.description}
                            </p>
                            <p className="p2">
                              <b className="bold me-1">Duration in month:</b>
                              {project?.duration_in_month}
                            </p>
                            <p className="p2">
                              <b className="bold me-1">Skills Apply:</b>
                              {project?.skill_apply}
                            </p>
                            <hr className="hr3" />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="line"></div>
                <div className="sright2">
                  {candidate?.candidates[0]?.objective && (
                    <div className="objective">
                      <p className="heading2">Objective</p>
                      <p className="p2">
                        {candidate?.candidates[0]?.objective}
                      </p>
                    </div>
                  )}
                  {/* Experience Section */}
                  {candidate?.experience?.length > 0 && (
                    <div className="experience">
                      <p className="heading2">Experience</p>
                      {candidate?.experience?.map((exp, idx) => (
                        <div className="pp" key={idx}>
                          <p className="p2">
                            <b>
                              {exp?.start_date}
                              {exp?.end_date ? ` - ${exp?.end_date}` : ""}
                            </b>
                          </p>
                          <p className="p2">
                            <b className="jobtitle2">{exp?.company_name}</b>
                          </p>
                          <p className="p2 jobtitle2">{exp?.designation}</p>
                          <p className="p2">{exp?.description}</p>
                          <hr className="hr3" />
                        </div>
                      ))}
                    </div>
                  )}
                  {/* Education Section */}
                  {candidate?.educational?.length > 0 && (
                    <div className="education">
                      <p className="heading2">Education</p>

                      <div className="pp">
                        {candidate?.educational?.map((edu, index) => (
                          <div key={index}>
                            <p className="p2">
                              <b className="me-1">Degree/Program:</b>
                              <span className="upercase ms-1">
                           
                                {edu?.education_name}
                              </span>
                            </p>
                            <p className="p2">
                              <b className="me-1">Passing Year:</b>
                              <span className="span22">{edu?.passing_year}</span>
                            </p>
                            <p className="p2">
                              <b className="me-1">Institute/University:</b>
                              <span className="span22">
                                {edu?.institute_name} /
                                {edu?.board_or_university}
                              </span>
                            </p>
                            <p className="p2">
                              <b className="me-1">Percentage/Grade:</b>
                              <span className="span22">
                                {edu?.percentage_or_grade}
                              </span>
                            </p>
                            <hr className="hr3" />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* Achievements Section */}
                  {candidate?.achievement?.length > 0 && (
                    <div className="achievements">
                      <p className="heading2">Achievements</p>
                      {candidate?.achievement?.map((ach, idx) => (
                        <div className="pp" key={idx}>
                          <p className="p22">
                            <b className="me-1 title2">Title:</b>
                            <b className="jobtitle2">{ach.title}</b>
                          </p>
                          <p className="p2">
                            <b className="me-1">Description:</b>
                            {ach.description}
                          </p>
                          <hr className="hr3" />
                        </div>
                      ))}
                    </div>
                  )}
                  {/* Add sections for experience, education, and achievements using similar structure */}
                </div>
              </div>
            </div>
            <div className="footer2 mt-5">
              <p className="footer-text2">
                Powered By{" "}
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link2"
                >
                  skillfresher.in
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UseTemp2;
