// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.profile-link {
  text-decoration: none;
  color: inherit;
}

.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  color: #333;
  cursor: pointer;
}

.profile-icon {
  font-size: 32px;
  color: #333;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-text {
  font-size: 18px;
  cursor: pointer;
  justify-content: center;
  text-align: center;
}
.candinfo {

  padding: 20px;
  border-radius: 8px;
  padding-bottom: 0px;

 
}

.candinfo-heading {
  font-weight: bold;
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
}

.candinfo p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 9px;
  color: #555;
}

.candinfo p strong {

  font-weight: bold;
}
/* 
.candinfo p {

  padding-bottom: 10px;
} */

.candinfo p:last-child {
  border-bottom: none; 
}
`, "",{"version":3,"sources":["webpack://./src/components/hr/ViewTest.css"],"names":[],"mappings":";AACA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,eAAe;EACf,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,uBAAuB;EACvB,kBAAkB;AACpB;AACA;;EAEE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;;;AAGrB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb;;AAEA;;EAEE,iBAAiB;AACnB;AACA;;;;GAIG;;AAEH;EACE,mBAAmB;AACrB","sourcesContent":["\r\n.profile-link {\r\n  text-decoration: none;\r\n  color: inherit;\r\n}\r\n\r\n.profile-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  gap: 8px;\r\n  font-size: 18px;\r\n  color: #333;\r\n  cursor: pointer;\r\n}\r\n\r\n.profile-icon {\r\n  font-size: 32px;\r\n  color: #333;\r\n  width: 50px;\r\n  height: 50px;\r\n  border-radius: 50%;\r\n  cursor: pointer;\r\n}\r\n\r\n.profile-text {\r\n  font-size: 18px;\r\n  cursor: pointer;\r\n  justify-content: center;\r\n  text-align: center;\r\n}\r\n.candinfo {\r\n\r\n  padding: 20px;\r\n  border-radius: 8px;\r\n  padding-bottom: 0px;\r\n\r\n \r\n}\r\n\r\n.candinfo-heading {\r\n  font-weight: bold;\r\n  font-size: 18px;\r\n  color: #333;\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.candinfo p {\r\n  font-size: 16px;\r\n  line-height: 1.6;\r\n  margin-bottom: 9px;\r\n  color: #555;\r\n}\r\n\r\n.candinfo p strong {\r\n\r\n  font-weight: bold;\r\n}\r\n/* \r\n.candinfo p {\r\n\r\n  padding-bottom: 10px;\r\n} */\r\n\r\n.candinfo p:last-child {\r\n  border-bottom: none; \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
