import React, { useState, useEffect } from "react";
import "./ModuleItem.css";
import TestService from "../../services/TestService";
import { useDispatch, useSelector } from "react-redux";
import { courseTitle } from "../../reduxdata/courseSlice";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ModuleItem(props) {
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const course = props.module.course_title;
  const [msg, setMsg] = useState();
  // console.log("course", course);

  // State to control modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(courseTitle({ course_title: course }));
  }, [dispatch, course]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleStartTest = async (level) => {
    const userId = user.userId;
    const moduleId = props.module.module_id;
    const token = localStorage.getItem("token");

    try {
      toast.dismiss();
      const response = await TestService.checkTestLimit(
        userId,
        moduleId,
        level,
        token
      );
      const result = await response.json();
      // console.log("test limit", response, result);

      const { status, msg, data } = result;

      if (status && data > 0) {
        navigate(`/course/question/${moduleId}/${level}`);
        closeModal();
      } else if (!status) {
        if (msg === "No test records found for the user.") {
          navigate(`/course/question/${moduleId}/${level}`);
          closeModal();
        } else if (
          msg === "No test attempts found for the given combination."
        ) {

          navigate(`/course/question/${moduleId}/${level}`);

          closeModal();
        } else if (msg === "User not found") {
          navigate(`/course/question/${moduleId}/${level}`);
          closeModal();
        }
      } else if (status && data === 0) {
        toast.error(
          <div
            dangerouslySetInnerHTML={{
              __html: `You have reached the maximum limit of attempts of Level ${level} for the module <strong>${props.module.module}</strong>. For more, please contact the admin.`,
            }}
          />
        );
      }
    } catch (error) {
      toast.error(`You have reached the maximum limit...`);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={9000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
        toastStyle={{ width: "700px" }}
          className="responsive-toast"
      />

      <tr>
        <td>{props.keys}</td>
        <td>{props.module.module}</td>
        <td>{props.module.description}</td>
        <td>
          <button
            type="button"
            className="btn btn-green text-nowrap"
            onClick={openModal} 
          >
            Start Test
          </button>
        </td>
      </tr>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="modal fade show"
          id={`exampleModal${props.module.module_id}`}
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          style={{ display: "block", paddingRight: "15px" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-between align-items-center">
                <div
                  className="modal-title text-center w-100"
                  id="staticBackdropLabel"
                >
                  <h5> {props.module.course_title}</h5>

                  <span className="text-black fw-bold fs-6">
                    {props.module.module}
                  </span>
                  <br />
                  <span className="text-black fw-normal fs-6">
                    {" "}
                    Select Level Of Test
                  </span>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                  }}
                ></button>
              </div>

              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => handleStartTest(1)} 
                >
                  Level 1
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => handleStartTest(2)}
                >
                  Level 2
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => handleStartTest(3)}
                >
                  Level 3
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
