import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./UserSlice";
import testReducer from "./TestSlice";
import adminReducer from "./AdminSlice";
import courseReducer from "./courseSlice";
import profileReducer from "./profileSlice";
import quesReducer from "./QuestSlice";
const store = configureStore({
  reducer: {
    user: userReducer,
    test: testReducer,
    admin: adminReducer,
    course: courseReducer,
    profile: profileReducer,
    ques: quesReducer,

  },
});

store.subscribe(() => {
  const mydata = store.getState().user.value;
  localStorage.setItem("user", JSON.stringify(mydata));
  // console.log(mydata)
});

export default store;
