import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./Success.css";
import { changeTestData } from "../reduxdata/TestSlice";

export default function Success() {
  const test = useSelector((state) => state.test.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleBackButton = (event) => {
      event.preventDefault();
      navigate("/course"); 
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  return (
    <>
      <br></br>
      <br></br>
      <div className="msg mt-5 rr" style={{}}>
        <h2 className="mt-5">Thank You!</h2>
        <h3 className="text-center">Your Submission has been received!</h3>
        <p>
          You can check your test results at{" "}
          <Link to="/testhistory">
            <span className="bold fs-4 text-green">TestHistory</span>
          </Link>
          ...
        </p>
      </div>
    </>
  );
}
