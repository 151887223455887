import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./UseTemp5.css";
import ResumeService, { urls } from "../../../services/ResumeService";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UseTemp5 = () => {
  const logindata = useSelector((state) => state.user.value);
const SERVER = process.env.REACT_APP_LIVE_SKILLEDFRESHER_SERVER;

  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const candidate = JSON.parse(localStorage.getItem("candidate"));

  const downloadPDF = () => {
    const input = document.getElementById("resume5"); // Adjust ID if necessary

    html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Scale ratio to fit canvas content within the PDF width
      const scaleRatio = pdfWidth / canvasWidth;
      const scaledHeight = canvasHeight * scaleRatio;

      // Define top and bottom padding in PDF units (e.g., mm)

      const topPadding = 6;
      const bottomPadding = 6;

      // Adjust available page height to account for top and bottom padding
      const adjustedPageHeight = pdfHeight - topPadding - bottomPadding;

      // Break down content into multiple pages if needed
      let yOffset = 0;
      while (yOffset < canvasHeight) {
        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = canvas.width;
        pageCanvas.height = Math.min(
          canvasHeight - yOffset,
          adjustedPageHeight / scaleRatio
        );

        const context = pageCanvas.getContext("2d");
        context.drawImage(
          canvas,
          0,
          yOffset,
          canvas.width,
          pageCanvas.height,
          0,
          0,
          canvas.width,
          pageCanvas.height
        );

        const pageData = pageCanvas.toDataURL("image/png");

        // Add content to the PDF with top padding on each page
        pdf.addImage(
          pageData,
          "PNG",
          0,
          yOffset === 0 ? 0 : topPadding, // No top padding for the first page
          pdfWidth,
          (pageCanvas.height * pdfWidth) / canvas.width
        );

        yOffset += pageCanvas.height;

        if (yOffset < canvasHeight) pdf.addPage(); // Add a new page if more content remains
      }

      pdf.save("profile.pdf");
    });
  };


  return (
    <>

      <div className="container">
        <div className="button-container5 row">
          <div className="col-12 col-sm-12 col-md-6">
            <button
              className="btn  bk5 back-button"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <button className="btn db5 download-button" onClick={downloadPDF}>
              Download as PDF
            </button>
          </div>
        </div>
        <div className="scroll-container5">
          <div className="container-fluid resume-container-5">
            <div className="row resume5" id="resume5">
              <div className="col-md-4 col-4 col-sm-4 resume-left5">
                <div className="profile-image5">
                  <img
                    src={
                      `${SERVER}/upload/user_image/${candidate?.candidates[0]?.image}` ||
                      "/path/to/default-image.png"
                    }
                  />
                </div>

                <hr className="hr5" />
                <div className="resume-section5">
                  <h2 className="section-title-6 text-center">{candidate?.name}</h2>
                  <ul>
                    <li>
                  <span className="d-flex"><FontAwesomeIcon icon={faLocationDot} className="me-1" />{candidate?.candidates[0]?.address}</span>
                    </li>
                    <li>
                      
                      <span className="d-flex"><FontAwesomeIcon icon={faPhone} className="me-1"/>{candidate?.contact}</span>
                    </li>
                    <li>
                      
                      <span className="d-flex"><FontAwesomeIcon icon={faEnvelope} className="me-1"/>{candidate?.email}</span>
                    </li>
                  </ul>
                </div>
                {candidate?.candidates[0]?.skills && (
                  <>
                    <hr className="hr5" />

                    <div className="resume-section5">
                      <h2 className="section-title-6">Skills</h2>
                      <ul>
                        <li className="skills-list-item">
                          {candidate?.candidates[0]?.skills}
                        </li>
                      </ul>
                    </div>
                  </>
                )}
                <hr className="hr5" />
                {(candidate?.candidates?.[0]?.linkedin_profile ||
                  candidate?.candidates?.[0]?.github_profile) && (
                  <>
                    <div className="resume-section5">
                      <h2 className="section-title-6">Social</h2>
                      <ul>
                        {candidate?.candidates[0]?.linkedin_profile && (
                          <li>
                          <span className="d-flex">
                            <FontAwesomeIcon icon={faLinkedin} className="me-1" />{candidate?.candidates[0]?.linkedin_profile}
                            </span>
                          </li>
                        )}
                        {candidate?.candidates[0]?.github_profile && (
                          <li>
                         <span className="d-flex">
                            <FontAwesomeIcon icon={faGithub} className="me-1"/>{candidate?.candidates[0]?.github_profile}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                    <hr className="hr5" />
                  </>
                )}
                {candidate?.candidates[0]?.language_known && (
                  <>
                    <div className="resume-section5">
                      <h2 className="section-title-6">Languages</h2>
                      <ul>
                        <li> {candidate?.candidates[0]?.language_known}</li>
                      </ul>
                    </div>
                    <hr className="hr5" />
                  </>
                )}
              </div>

              <div className="col-md-8 col-8 col-sm-8 resume-right5">
                {candidate?.candidates[0]?.objective && (
                  <div className="resume-section5">
                    <h2 className="section-title5">Career Objective</h2>
                    <p className="p5"> {candidate?.candidates[0]?.objective}</p>
                  </div>
                )}
                {candidate?.experience?.length > 0 && (
                  <>
                    <hr className="hr5" />

                    <div className="resume-section5">
                      <h2 className="section-title5">Experience</h2>
                      {candidate?.experience?.map((exp, index) => (
                        <div key={index} className="experience-item5">
                          <p className="p5">
                            <span className="bold-title5 jobtitle5">
                              {exp?.designation}
                            </span>
                            &nbsp;
                            <span className="bold-title5">
                              [ {exp?.start_date}
                              {exp?.end_date ? ` to ${exp?.end_date}` : ""} ]
                            </span>
                          </p>
                          <p className="p5">
                            <b className="bold5 jobtitle5">{exp?.company_name}</b>
                          </p>

                          <p className="p5">
                            <p className="p5">{exp?.description}</p>
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {candidate?.educational?.length > 0 && (
                  <>
                    <hr className="hr5" />
                    <div className="resume-section5">
                      <h2 className="section-title5">Education</h2>
                      {candidate?.educational?.map((edu, index) => (
                        <div key={index} className="education-item5">
                          <p className="p5">
                            <b className="bold me-1">Degree/Program:</b>
                            {edu?.education_name}
                          </p>
                          <p className="p5">
                            <b className="bold me-1">Institute/University:</b>
                            {edu?.board_or_university}
                            <span className=" ms-2">{edu?.passing_year}</span>
                          </p>
                          <p className="p5">
                            <b className="bold">Percentage/Grade: </b>
                            <span className=" ms-2">
                              {edu?.percentage_or_grade}
                            </span>
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {candidate?.project?.length > 0 && (
                  <>
                    <hr className="hr5" />
                    <div className="resume-section5">
                      <h2 className="section-title5">Project</h2>
                      {candidate?.project?.map((project, index) => (
                        <div key={index} className="education-item5">
                          <p className="p5 bold5">
                            <b className="bold me-1 ">Project Title:</b>
                    <span className="jobtitle5">{project?.project_title}</span> 
                          </p>
                          <p className="p5">
                            <b className="bold me-1">Skills Apply:</b>
                            <span className=" mr-2 ">
                              {project?.skill_apply}
                            </span>
                          </p>
                          <p className="p5">
                            <b className="bold me-1">Duration in month:</b>
                            <span className=" mr-2">
                              {project?.duration_in_month}
                            </span>
                          </p>
                          <p className="p5">
                            <b className="bold me-1">Description:</b>
                            <span className=" mr-2">
                              {project?.description}
                            </span>
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {candidate?.achievement?.length > 0 && (
                  <>
                    <hr className="hr5" />
                    <div className="resume-section5">
                      <h2 className="section-title5">Achievements</h2>
                      {candidate.achievement.map((achievement, index) => (
                        <div key={index} className="education-item5">
                          <p className="p5 bold5">
                            <b className="bold me-1">Title:</b><span className="jobtitle5">{achievement?.title}</span>
                          </p>
                          <p className="p5">
                            <b className="bold me-1">Description:</b>
                            <span>
                              {achievement?.description}
                            </span>
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>

              <div className="footer5 mt-4">
                <p className="footer-text5">
                  Powered By{" "}
                  <a
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link5"
                  >
                    skillfresher.in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UseTemp5;
