// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-pills .nav-link.active {
  background-color: #1ABC9C;
  color: #fff;
}

.nav-link.active {
  color: #fff !important; 
  font-weight: bold; 
}

.nav-link:hover{
  color: #1ABC9C;
}

.nav-link.active:hover{
  color: #fff;
}

.active {
  color: #fff !important;
  background-color: #1ABC9C;
  font-weight: bold;
}

.navbar-nav .dropdown-menu{
  background-color: #eee;
}

.navbar-nav .dropdown-menu a{
line-height: 2;
}
.nav-link.active {
  color: #fff !important;
  --bs-nav-link-color:#fff !important;
}
.nav-link.active :hover{
  color: #fff !important;
 
}`, "",{"version":3,"sources":["webpack://./src/components/Menu.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;AACA,cAAc;AACd;AACA;EACE,sBAAsB;EACtB,mCAAmC;AACrC;AACA;EACE,sBAAsB;;AAExB","sourcesContent":[".nav-pills .nav-link.active {\r\n  background-color: #1ABC9C;\r\n  color: #fff;\r\n}\r\n\r\n.nav-link.active {\r\n  color: #fff !important; \r\n  font-weight: bold; \r\n}\r\n\r\n.nav-link:hover{\r\n  color: #1ABC9C;\r\n}\r\n\r\n.nav-link.active:hover{\r\n  color: #fff;\r\n}\r\n\r\n.active {\r\n  color: #fff !important;\r\n  background-color: #1ABC9C;\r\n  font-weight: bold;\r\n}\r\n\r\n.navbar-nav .dropdown-menu{\r\n  background-color: #eee;\r\n}\r\n\r\n.navbar-nav .dropdown-menu a{\r\nline-height: 2;\r\n}\r\n.nav-link.active {\r\n  color: #fff !important;\r\n  --bs-nav-link-color:#fff !important;\r\n}\r\n.nav-link.active :hover{\r\n  color: #fff !important;\r\n \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
