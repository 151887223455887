const SERVER = process.env.REACT_APP_LIVE_SKILLEDFRESHER_SERVER

class AdminService {
  constructor() {
    this.token = localStorage.getItem("token");
  }

  

  getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  addCourse(data, token) {
    // const data = {
    //   course_title,
    //   description,
    //   active_status,
    // };

    console.log("data",data)
    return fetch(`${SERVER}/api/course/save`, {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },

      body: data,
   
    });
  }

  addModule(module_title, description, active_status, token) {
    const data = {
      module_title,
      description,
      active_status,
     
    };
    return fetch(`${SERVER}/api/module/save`, {
      method: "POST",
      // headers: this.getHeaders(),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  }

  addCoursesModules(course, module, token) {
    const data = {
      course,
      module,
    };
    return fetch(`${SERVER}/api/courseModule/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  }

  addQuestion(data, token) {
    return fetch(`${SERVER}/api/question/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify(data),
    });
  }

  getAllUser(token) {
    return fetch(`${SERVER}/api/user/list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    });
  }

  deleteCourse(course_id, token) {
    return fetch(`${SERVER}/api/course/delete/${course_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  deleteModule(module_id) {
    return fetch(`${SERVER}/api/module/delete/${module_id}`, {
      method: "DELETE",
      headers: this.getHeaders(),
    });
  }

  deletecourseModule(course_module_id) {
    return fetch(
      `${SERVER}/api/courseModule/delete/${course_module_id}`,
      {
        method: "DELETE",
        headers: this.getHeaders(),
      }
    );
  }

  deleteQues(ques_id) {
    return fetch(`${SERVER}/api/question/delete/${ques_id}`, {
      method: "DELETE",
      headers: this.getHeaders(),
    });
  }

  dleteUser(userid, token) {
    return fetch(`${SERVER}/api/delete/${userid}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    });
  }

  updateCourse(course_id, updatedCourse,token) {
    return fetch(`${SERVER}/api/course/update/${course_id}`, {
      method: "PUT",
      body: updatedCourse,
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateModule(module_id, updatedModule,token) {
    return fetch(`${SERVER}/api/module/update/${module_id}`, {
      method: "PUT",
      body: JSON.stringify(updatedModule),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateCourseModule(course_module_id, updatedCM,token) {
    return fetch(
      `${SERVER}/api/coursemodule/update/${course_module_id}`,
      {
        method: "PUT",
        body: JSON.stringify(updatedCM),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  updateQuestion(ques_id, updatedQuestions,token) {
    return fetch(`${SERVER}/api/question/update/${ques_id}`, {
      method: "PUT",
      body: JSON.stringify(updatedQuestions),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateUserRole(updatedrole,userid, token) {
    return fetch(`${SERVER}/api/users/block/${userid}`, {
      method: "PUT",
      body: JSON.stringify(updatedrole),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    });
  }

  searchUser(query, token) {
    return fetch(`${SERVER}/api/search?q=${query}`, {
      // headers: this.getHeaders(),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    });
  }
}

export default new AdminService();
