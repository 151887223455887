const SERVER = process.env.REACT_APP_LIVE_SKILLEDFRESHER_SERVER;

class RegisterService {
  constructor() {
    this.token = localStorage.getItem("token"); 
  }

  getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }
  RegisterUser(data) {
    // console.log(data)
    return fetch(
      `${SERVER}/user/userregister`,

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
  }

  RegisterUsertwo(data) {
    // console.log(data)
    return fetch(
      "https://resume-builder.skilledfresher.in/register_api/",

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
  }
  getuserbyemail(email) {
    // console.log('>>>>>>>>>>>>>>>>>> ', email)

    return fetch(`${SERVER}/api/user` + "/" + email);
  }

  getuserbycontact(contact) {
    // console.log('>>>>>>>>>>>>>>>>>> ', contact)

    return fetch(`${SERVER}/api/userbycontact` + "/" + contact);
  }

  verifyEmail(userId, vcode) {
    return fetch(`${SERVER}/user/verifyEmail` + "/" + userId, {
      method: "POST",
      body: JSON.stringify({ vcode }), // Wrap vcode in an object
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  forgotPassword(email) {
    // console.log(email);
    return fetch(`${SERVER}/user/forgotPassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }), // Wrap vcode in an object
    });
  }

  updatePassword(userId, passwordData, token) {
    // console.log("servicedata", userId, passwordData);
    return fetch(`${SERVER}/api/changePassword/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify(passwordData),
    });
  }

  profile(userId, token) {
    // console.log(userId);
    return fetch(`${SERVER}/api/profile/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    }).then((response) => response.json());
  }

  updateProfile(userId, token, profileData) {
    // console.log("hello world", JSON.stringify(profileData));
    return fetch(`${SERVER}/api/users/update/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify(profileData),
    }).then((response) => response.json());
  }
}

export default new RegisterService();
