import React from "react";
import QuestionsService from "../../services/QuestionsService";

import TestService from "../../services/TestService";
import ModulesService from "../../services/ModulesService";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../withRouter";

import { useEffect, useState } from "react";
import { configureStore } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "../question/QuestionItem.css";

function History(props) {
  const loginUser = useSelector((state) => state.user.value);

  const [testing, setTest] = useState([]);
  const [color, setColor] = useState("");
  const [answer, setSelectAnswer] = useState();
  const [module, setModule] = useState([]);
  const navigate = useNavigate();
  const [questionLevel, setQuestionLevel] = useState(null);

  let { testid } = useParams();

  useEffect(() => {
    TestService.getTestByTestId(testid, loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        setTest(data?.test?.test);
        // console.log("data1", data);

        if (data?.test?.test && data.test.test.length > 0) {
          const level = data.test.test[0]?.quesLevel; // Assuming we want the first question's level
          setQuestionLevel(level); // Store the level in state
          // console.log("Stored Question Level:", level);
        }

      });

    TestService.getModuleByTestId(testid, loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        setModule(data?.moduletitle?.module);
        // console.log("dat2a", data);
      });
  }, []);

  return (
    <>
      <div>
        <div className="container mt-5">
          <div className="row mb-2">
            <div className="col-12 col-md-2 col-lg-2">
              <button
                className="btn bk-hr back-button "
                onClick={() => {
                  navigate(-1);
                  window.scrollTo(0, 0);
                }}
              >
                Back
              </button>
            </div>
            {/* <div className="col-12 col-md-10 col-lg-10">
              <div className="d-flex module1 justify-content-between align-items-center p-3 ">
                <div className=" position-sticky mx-3">
                  MODULE : &nbsp;
                  {module?.map((m) => m.module_title)}
                </div>
                <div className="mx-3"></div>
                <div className="queqty mx-3">
                  Result: &nbsp;{" "}
                  {testing.reduce((acc, ob) => {
                    return acc + (ob.isright === 1 ? 1 : 0);
                  }, 0)}{" "}
                  /{testing.length}
                  <br/>
                  Question Level: &nbsp; 
                  {questionLevel}
                </div>{" "}

              </div>
            </div> */}
            <div className="col-12 col-md-10 col-lg-10">
  <div className="d-flex module1 justify-content-between p-3">
    <div className="position-sticky mx-3">
      MODULE : &nbsp;
      {module?.map((m) => m.module_title)}
    </div>
    <div className="mx-3"></div>
    <div className="queqty mx-3 d-flex flex-column align-items-start">
      <div className="result">
        Result: &nbsp;
        {testing.reduce((acc, ob) => {
          // Increment the score if the answer is correct (isright === 1)
          return acc + (ob.isright === 1 ? 1 : 0);
        }, 0)}{" "}
        /{testing.length}
      </div>
      <div className="question-level ">
       Level: &nbsp; 
        {questionLevel}
      </div>
    </div>
  </div>
</div>

          </div>
          <div className=" col-lg-12 col-md-12 col-sm-12">
            <form>
              <div
                className="content-scrollable"
                style={{
                  maxHeight: "calc(100vh - 150px)",
                  overflowY: "auto",
                  scrollbarWidth: "none",
                }}
              >
                {testing.map((ob, index) => {
                  return (
                    <>
                      <div className="box ">
                        <div className="bold fs-5 ">
                          Q.&nbsp;{index + 1}
                          &nbsp;
                          {ob.question}
                        </div>
                        <br />
                        <div
                          style={{
                            borderRadius: "8px",
                            backgroundColor:
                              ob.correctAns == "a"
                                ? "#c3e6cb"
                                : "" || ob.answer == "a"
                                ? "#f5c6cb"
                                : "",
                          }}
                        >
                          &nbsp;
                          {ob.option1}
                        </div>
                        <br />
                        <div
                          style={{
                            borderRadius: "8px",
                            backgroundColor:
                              ob.correctAns == "b"
                                ? "#c3e6cb"
                                : "" || ob.answer == "b"
                                ? "#f5c6cb"
                                : "",
                          }}
                        >
                          &nbsp;
                          {ob.option2}
                        </div>
                        <br />
                        <div
                          style={{
                            borderRadius: "8px",
                            backgroundColor:
                              ob.correctAns == "c"
                                ? "#c3e6cb"
                                : "" || ob.answer == "c"
                                ? "#f5c6cb"
                                : "",
                          }}
                        >
                          &nbsp;
                          {ob.option3}
                        </div>
                        <br />
                        <div
                          style={{
                            borderRadius: "8px",
                            backgroundColor:
                              ob.correctAns == "d"
                                ? "#c3e6cb"
                                : "" || ob.answer == "d"
                                ? "#f5c6cb"
                                : "",
                          }}
                        >
                          &nbsp;
                          {ob.option4}
                        </div>
                        <br />
                      </div>
                    </>
                  );
                })}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(History);
