import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import ResumeService, { urls } from "../../../services/ResumeService";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Experience() {
  const logindata = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [experienceList, setExperienceList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [experienceId, setExperienceId] = useState(undefined);
  const experienceListRef = useRef(null); // Ref for scrolling
  const [showForm, setShowForm] = useState();

  const formRef = useRef(null); // Ref for the form section
  const messageRef = useRef(null);

  const company_nameRef = useRef(null);
  const designationRef = useRef(null);
  const start_dateRef = useRef(null);
  const end_dateRef = useRef(null);
  const job_statusRef = useRef(null);
  const descriptionRef = useRef(null);

  const today = new Date().toISOString().split("T")[0];

  const fetchExperienceList = async () => {
    try {
      setLoading(true);
      const fetchUrl = `${urls.Experience_List}`;

      // console.log("Fetching experience list from:", fetchUrl);

      const response = await ResumeService.GetApiCallWithToken(
        fetchUrl,
        logindata.token
      );

      // console.log("Fetched Experience list Response:", response);

      if (
        Array.isArray(response.data.experience) &&
        response?.data?.experience.length > 0
      ) {
        setExperienceId(response?.data?.experience[0]?.experience_id);
      } else {
      }

      if (response?.data?.data) {
        setExperienceList(response?.data?.data);
      } else {
      }
    } catch (error) {
      setMsg("An error occurred while fetching experiences.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchExperienceList();
  }, [logindata?.token]);

  const addExperience = async (experienceDetails, token) => {
    try {
      toast.dismiss();
      const response = await ResumeService.PostApiCallWithToken(
        urls.Experience_Add,
        experienceDetails,
        token
      );
      fetchExperienceList();
      // console.log("Save Experience Response", response);
      toast.success(
        response?.data?.message || "Experience added successfully."
      );
      return response;
    } catch (error) {
      toast.dismiss();
      setMsg(error.response?.data?.message || "Failed to add experience.");
      throw error;
    }
  };

  const deleteExperience = async (experienceId, token) => {
    try {
      const response = await ResumeService.DelApiCall(
        `${urls.Experience_Delete}/${experienceId}`,
        token
      );
      // console.log("Delete Experience Response", response);
      toast.success(
        response?.data?.message || "Experience deleted successfully."
      );
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to delete experience."
      );
    }
  };

  // Calculate duration between start date and end date
  const calculateDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : new Date();
    const diffInMonths =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());

    const years = Math.floor(diffInMonths / 12);
    const months = diffInMonths % 12;
    return `${years > 0 ? `${years} year ` : ""}${months} months`;
  };

  // Handle form submit
  const handleExperienceSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    const start_date = start_dateRef.current?.value || "";
    const end_date = end_dateRef.current?.value || null;
    const duration = calculateDuration(start_date, end_date);
    const experienceDetails = {
      person: logindata.userId,
      company_name: company_nameRef.current?.value || "",
      designation: designationRef.current?.value || "",
      start_date: start_date,
      end_date: end_date,
      job_status: job_statusRef.current?.checked ? "true" : "false",
      description: descriptionRef.current?.value || "",
      duration: duration,
    };

    // Validation
    if (!experienceDetails.company_name.trim())
      errors.company_name = "Company Name field is required";
    if (!experienceDetails.designation.trim())
      errors.designation = "Designation field is required";
    if (!experienceDetails.start_date.trim())
      errors.start_date = "Start Date field is required";
    // if (!experienceDetails.end_date.trim())
    //   errors.end_date = "End Date field is required";
    if (
      experienceDetails.end_date &&
      isNaN(Date.parse(experienceDetails.end_date))
    )
      if (!experienceDetails.description.trim())
        errors.description = "Description field is required";

    const company_name = company_nameRef.current.value.trim();
    const designation = designationRef.current.value.trim();
    const todayDate = start_dateRef.current.value.trim();
    const end_dates = end_dateRef.current.value.trim();
    const job_status = job_statusRef.current.value.trim();
    const description = descriptionRef.current.value.trim();

    // Validation
    if (!company_name) {
      errors.company_name = "Company name field is required.";
    } else if (company_name.length > 100) {
      errors.company_name = "Company Name cannot exceed 100 characters.";
    }

    if (!designation) {
      errors.designation = "Designation field is required.";
    } else if (designation.length > 50) {
      errors.designation = "Designation cannot exceed 50 characters.";
    } else if (/\d/.test(designation)) {
      errors.designation = "Designation cannot contain numbers.";
    }

    if (!start_date) {
      errors.start_date = "Start date field is required.";
    } else if (new Date(start_date) > new Date(todayDate)) {
      errors.start_date = "Start date cannot be in the future.";
    }

    if (end_dates) {
      if (new Date(start_date) > new Date(end_dates)) {
        errors.end_date = "End date cannot be earlier than start date.";
      }
    }

    if (!description) {
      errors.description = "Job description field is required.";
    } else if (description.length > 500) {
      errors.description = "Job description cannot exceed 500 characters.";
    }

    if (Object.keys(errors).length === 0) {
      toast.dismiss();
      setLoading(true);
      try {
        let response;
        let updateData = [...experienceList];

        if (isEditMode && editIndex !== null) {
          response = await ResumeService.PutApiCall(
            `${urls.Experience_Update}/${experienceId}`,
            experienceDetails,
            logindata.token
          );
          // console.log("Edit Experience response: ", response);
          fetchExperienceList();
          toast.success("Experience updated successfully.");
        } else {
          // Add new experience
          response = await addExperience(experienceDetails, logindata.token);
          setExperienceList((prevList) => [...prevList, experienceDetails]);
        }
        setExperienceList(updateData);
        // toast.success("Experience added successfully.");
        resetForm();
        setTimeout(() => setMsg(""), 2000);
        localStorage.setItem("experienceData", JSON.stringify(updateData));
      } catch (error) {
        toast.dismiss();
        setMsg(error.message);
      } finally {
        setLoading(false);
        setIsEditMode(false);
        setEditIndex(null);
        setFormErrors({});
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Clear the error related to the specific field when user starts typing
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Reset the error for this specific field
    }));

    // Update form data
    setFormData({ ...formData, [name]: value });
  };

  // Reset form after submit
  const resetForm = () => {
    company_nameRef.current.value = "";
    designationRef.current.value = "";
    start_dateRef.current.value = "";
    end_dateRef.current.value = "";
    job_statusRef.current.checked = false;
    descriptionRef.current.value = "";
    setFormErrors({}); // Reset form errors as well
    setIsFormSubmitted(false);
    setShowForm(false);
    setIsEditMode(false);
    setTimeout(() => {
      setMsg("");
      setShowForm(true);
    }, 2000);
  };

  // Define handleEdit function
  const handleEdit = (index) => {
    // console.log("index", index);
    setIsEditMode(true);
    setEditIndex(index);
    const selectedExperience = experienceList[index];
    // console.log("experienceList", experienceList);

    localStorage.setItem("exp_id", selectedExperience.experience_id);
    // console.log("Editing Experience ID:", selectedExperience.experience_id);
    company_nameRef.current.value = selectedExperience.company_name;
    designationRef.current.value = selectedExperience.designation;
    start_dateRef.current.value = selectedExperience.start_date;
    end_dateRef.current.value = selectedExperience.end_date;
    job_statusRef.current.checked = selectedExperience.job_status;
    descriptionRef.current.value = selectedExperience.description;
    const experienceId = selectedExperience?.experience_id || "ID Not Found";
    // console.log("Setting Experience ID:", experienceId);
    // setExperienceId(experienceId);
  };
  const handleDelete = async (experienceId) => {
    if (!experienceId) {
      // console.error("Experience ID is undefined:", experienceId);
      return;
    }

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this experience entry?"
    );

    if (isConfirmed) {
      toast.dismiss();
      try {
        setLoading(true);
        const response = await deleteExperience(experienceId, logindata.token);

        if (response?.status === 200) {
          setExperienceList((prevList) =>
            prevList.filter(
              (experience) => experience.experience_id !== experienceId
            )
          );

          setMsg(response?.data?.message || "Experience deleted successfully.");
          resetForm();
        } else {
          setMsg(response?.data?.message);
        }
        setTimeout(() => setMsg(""), 2000);
      } catch (error) {
        toast.dismiss();
        setMsg(error.message);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleCancel = () => {
    resetForm(); 
    setIsEditMode(false);
    setEditIndex(null); 
  };

  return (
    <>
      <ToastContainer
        position="top-center" 
        autoClose={2000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
        className="responsive-toast"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-2 col-sm-2 col-md-2 mb-3">
            <Sidebar />
          </div>
          <div className="col-lg-8 col-8 col-sm-8 offset-3 col-sm-8 col-md-10">
            <div className="row">
              {experienceList.length > 0 && (
                <div className="col-lg-5 col-md-5" ref={experienceListRef}>
                  <div className="border border-success rounded p-4 bg-light shadow">
                    {/* <h2 className="text-center">Saved Experience</h2> */}
                    {/* <hr /> */}
                    <div className="table-responsive table-responsive-resume">
                      <table className="table table-striped">
                        <tbody>
                          {experienceList.map((experience, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <th className="text-left">Company Name</th>
                                <td className="text-left">
                                  {experience?.company_name}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">Designation</th>
                                <td className="text-left">
                                  {experience?.designation}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">Joining Date</th>
                                <td className="text-left">
                                  {experience?.start_date}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">Releaving Date</th>
                                <td className="text-left">
                                  {experience?.end_date || "-"}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">Presently Working</th>
                                <td className="text-left">
                                  {experience?.job_status ? "Yes" : "No"}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">Job Description</th>
                                <td className="text-left">
                                  {experience?.description}
                                </td>
                              </tr>

                              <tr>
                                <th className="text-left">Duration</th>
                                <td className="text-left">
                                  {calculateDuration(
                                    experience?.start_date,
                                    experience?.end_date
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={2}>
                                  <div className="d-flex justify-content-between">
                                    <button
                                      className="btn btn-md btn-danger mt-3  "
                                      onClick={() =>
                                        handleDelete(experience?.experience_id)
                                      }
                                    >
                                      Delete
                                    </button>
                                    <button
                                      className="btn btn-md  btn-success mt-3"
                                      onClick={() => {
                                        handleEdit(index);
                                        setExperienceId(
                                          experience?.experience_id
                                        );
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </td>
                              </tr>

                              <tr
                                className="spacer-row"
                                ref={
                                  index === experienceList.length - 1
                                    ? experienceListRef
                                    : null
                                }
                              ></tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}


              <div
              className={`col-lg-${experienceList.length === 0 ? "6" : "6"} col-md-${experienceList.length === 0 ? "6" : "5"} offset-lg-${
                experienceList.length === 0 ? "3" : "1"
              } mi`}
                ref={formRef}>
                <div className="border border-success rounded p-4 bg-light shadow mb-4">
                  <h3 className="text-center">
                    {isEditMode ? "Update Experience" : " Add New Experience"}
                  </h3>
                  <hr />

                  <form onSubmit={handleExperienceSubmit}>
                    <div className="form-group">
                      <label htmlFor="company Name">
                        Company Name: <span className="text-danger">*</span>
                      </label>
                      <input
                        name="company Name"
                        className={`form-control form-control-bg ${formErrors.company_name ? "is-invalid" : ""
                          }`}
                        ref={company_nameRef}
                        maxLength={100}
                        placeholder="ex. - TCS"
                        required
                        onChange={handleInputChange}
                      />
                      {formErrors.company_name && (
                        <div className="invalid-feedback">
                          {formErrors.company_name}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="designation">
                        Designation: <span className="text-danger">*</span>
                      </label>
                      <input
                        name="designation"
                        className={`form-control form-control-bg ${formErrors.designation ? "is-invalid" : ""
                          }`}
                        ref={designationRef}
                        maxLength={100}
                        placeholder="ex. - Team Lead"
                        onChange={handleInputChange}
                        required
                      />
                      {formErrors.designation && (
                        <div className="invalid-feedback">
                          {formErrors.designation}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="joining_date">
                            Joining Date <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            name="start_date"
                            className={`form-control form-control-bg ${formErrors.start_date ? "is-invalid" : ""
                              }`}
                            ref={start_dateRef}
                            max={today}
                            onChange={handleInputChange}
                            required
                          />
                          {formErrors.start_date && (
                            <div className="invalid-feedback">
                              {formErrors.start_date}
                            </div>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label htmlFor="end_date">End Date</label>
                          <input
                            type="date"
                            name="end_date"
                            className={`form-control form-control-bg ${formErrors.end_date ? "is-invalid" : ""
                              }`}
                            onChange={handleInputChange}
                            ref={end_dateRef}
                          />
                          {formErrors.end_date && (
                            <div className="invalid-feedback">
                              {formErrors.end_date}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="jobStatus"
                          name="presently_working"
                          ref={job_statusRef}
                          onChange={handleInputChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="job_status"
                        >
                          Presently Working
                        </label>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="job_description">
                        Job Description <span className="text-danger">*</span>
                      </label>
                      <textarea
                        name="description"
                        className={`form-control form-control-bg ${formErrors.description ? "is-invalid" : ""
                          }`}
                        ref={descriptionRef}
                        rows={4}
                        maxLength={500}
                        placeholder="ex. - Describe Your Job Responsibility"
                        onChange={handleInputChange}
                        required
                      />
                      {formErrors.description && (
                        <div className="invalid-feedback">
                          {formErrors.description}
                        </div>
                      )}
                    </div>

                    <button
                      className="btn  btn-md btn-success mt-3"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Saving..." : isEditMode ? "Update" : "Save"}
                    </button>
                    {isEditMode && (
                      <button
                        type="button"
                        className="btn btn-secondary ms-2 mt-3"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Experience;
