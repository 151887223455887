import cv3 from "../../../Assets/Images/abc.png";

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import ResumeService, { urls } from "../../../services/ResumeService";

const Temp3 = ({ showHover }) => {
  const isUser = JSON.parse(localStorage.getItem("user"));
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleTemplateClick = () => {
    if (isUser?.islogin) {
      handleShowModal();
    } else {
      window.location.href = "/";
    }
  };

  const logindata = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [candidate, setCandidate] = useState(null);
  const [msg, setMsg] = useState("");
  const [recordId, setRecordId] = useState(undefined);
  const navigate = useNavigate();
  const candidateInfo = JSON.parse(localStorage.getItem("CandidateInfo"));
  const handleButtonClick = async () => {
    try {
      setLoading(true);

      const data = {
        template: "use3",
      };
      const Updateurl = `${urls.Update_template}/${candidateInfo.data.data.id}`;
      const Updateresponse = await ResumeService.PutApiCall(
        Updateurl,
        data,
        logindata.token
      );

      if (Updateresponse?.status === 200) {
        setMsg(
          Updateresponse?.data?.message ||
            "User information updated successfully!"
        );

        setLoading(true);
        const fetchUrl = `${urls.Candidate_Details}/${logindata.userId}`;
        const response = await ResumeService.GetApiCallWithToken(
          fetchUrl,
          logindata.token
        );

        if (response?.data?.status) {
          const can_info = response?.data?.data;
          setCandidate(response?.data?.data);
          localStorage.setItem("candidate", JSON.stringify(can_info));
          navigate("/resumehome/use3");
        } else {
          setMsg("Candidate information could not be retrieved.");
        }

        setMsg("");
      } else {
        setMsg(
          Updateresponse?.data?.message || "Failed to update user information"
        );
      }
    } catch (error) {
      setMsg(error.response?.data?.message || "Error submitting the form");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container hover-area disable-hover">
        <img
          src={cv3}
          alt=""
          width="350"
          height="450"
          className="image_template"
          style={{cursor:"pointer"}}
          onClick={handleButtonClick}
        />
        <div style={{ position: "relative" }}>
          {isUser?.islogin ? (
            <button
              className="button1  rounded-corners use"
              style={{
                borderRadius: "10px",
                position: "absolute",
                bottom: "26.5rem",
                left: "90%",
               transform: "translateX(-50%)",
              }}
              onClick={handleButtonClick}
              disabled={loading}
            >
              {loading ? (
                <div className="text-center" role="status">
                  Loading{" "}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                "USE THIS"
              )}
            </button>
          ) : (
            <button
              className="button1  rounded-corners use"
              style={{
                borderRadius: "10px",
                position: "absolute",
                bottom: "26.5rem",
                left: "90%",
               transform: "translateX(-50%)",
              }}
            >
              <Link to="/">
                {loading ? (
                  <div className="text-center" role="status">
                    Loading{" "}
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                ) : (
                  "USE THIS"
                )}
              </Link>
            </button>
          )}
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Resume sample</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <img
            src={cv3}
            alt="Template 1"
            style={{ maxWidth: "100%", maxHeight: "70vh" }}
          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Temp3;
