import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ResumeService, { urls } from "../../../services/ResumeService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./Information.css";
import { setProfileImage } from "../../../reduxdata/profileSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUserCircle } from "react-icons/fa";

function AddInfo() {
  const logindata = useSelector((state) => state.user.value);

  // console.log("logindata", logindata);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [use1, setUse1] = useState("use1");
  const today = new Date().toISOString().split("T")[0];
  // Calculate date 18 years ago
  const maxDateForDOB = new Date();
  maxDateForDOB.setFullYear(maxDateForDOB.getFullYear() - 18);
  const maxDOB = maxDateForDOB.toISOString().split("T")[0];

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [msg, setMsg] = useState("");
  const [imagePreview, setImagePreview] = useState(null);

  const dobRef = useRef();
  const genderRef = useRef();
  const fatherNameRef = useRef();

  const profileImageRef = useRef();
  const addressRef = useRef();
  const github_profile_Ref = useRef();
  const linkedin_profile_Ref = useRef();

  const handleImageChange = () => {
    if (profileImageRef.current.files && profileImageRef.current.files[0]) {
      const file = profileImageRef.current.files[0];

      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);

      dispatch(setProfileImage(imageUrl));
      setErrors((prev) => ({ ...prev, profileImage: "" }));
    }
  };

  const validateInputs = () => {
    const newErrors = {};
    if (!dobRef.current.value) newErrors.dob = "Date of Birth is required.";
    if (!genderRef.current.value) newErrors.gender = "Gender is required.";
    if (!fatherNameRef.current.value)
      newErrors.fatherName = "Father's name is required.";
    if (!profileImageRef.current.files.length)
      newErrors.profileImage = "Please upload a profile image.";
    if (!addressRef.current.value) newErrors.address = "Address is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateInputs()) return;

    const dateb = dobRef.current.value;
    const gender = genderRef.current.value;
    const fathernm = fatherNameRef.current.value;
    const image = profileImageRef.current.files[0];

    const address = addressRef.current.value;
    const linkpfl = linkedin_profile_Ref.current.value;
    const githubprl = github_profile_Ref.current.value;

    const fData = new FormData();

    fData.append("image", image);
    fData.append("date_of_birth", dateb);
    fData.append("gender", gender);
    fData.append("father_name", fathernm);
    fData.append("address", address);
    fData.append("linkedin_profile", linkpfl);
    fData.append("github_profile", githubprl);
    fData.append("template", use1);
    fData.append("person", logindata.userId);

    try {
      toast.dismiss();
      setLoading(true);
      const response = await ResumeService.PostApiCallWithToken(
        urls.Add_Info,
        fData
       
      );
      // console.log("Add Info Response:", response);
      if (response?.data?.status) {
        // console.log("Success:", response.data);
        setMsg(response?.data?.msg);
        toast.success(response?.data?.msg || "Profile updated successfully!");

        if (image) {
          const imageUrl = URL.createObjectURL(image); 
          dispatch(setProfileImage(imageUrl)); 
        }

        navigate("/resumehome/information");
      } else {
        setMsg(response?.data?.msg);
      }
    } catch (error) {
      if (error.response) {
      } else if (error.request) {
      } else {
      }
    } finally {
      setLoading(false);
    }
  };
  const handleFocus = (field) => {
    setErrors((prev) => ({ ...prev, [field]: "" }));
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
        className="responsive-toast"
      />
      <div className="container col-md-6 col-lg-5 border border-success rounded p-4  bg-light shadow mt-5">
        <form id="myForm" onSubmit={handleSubmit}>
          <h3 className="mb-3 text-secondary">Add Basic Info</h3>
          <div className="row justify-content-center align-items-center">
            <div
              className="text-center mb-4 position-relative"
              style={{ width: "150px", height: "150px" }}
            >
              {/* <img
                src={imagePreview}
                id="image"
                className="rounded-circle profile-image"
                alt="Profile"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                }}
              /> */}

              {imagePreview ? (
                <img
                  src={imagePreview}
                  id="image"
                  className="rounded-circle profile-image"
                  alt="Profile"
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <FaUserCircle
                  style={{
                    width: "150px",
                    height: "150px",
                    color: "#ccc",
                  }}
                />
              )}

              <label
                htmlFor="profileImage"
                className="position-absolute top-50 start-50 translate-middle-info file-upload-icon"
                style={{
                  cursor: "pointer",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  borderRadius: "50%",
                  padding: "10px",
                }}
              >
                <i className="fas fa-upload text-white"></i>
              </label>
              <input
                type="file"
                className="form-control form-control-bg"
                id="profileImage"
                name="profileImage"
                ref={profileImageRef}
                style={{ display: "none" }}
                onChange={handleImageChange}
                onFocus={() => handleFocus("profileImage")}
              />
            </div>

            {errors.profileImage && (
              <span
                className="text-danger mt-2 mb-2 text-center"
                style={{ textWrap: "nowrap" }}
              >
                {errors.profileImage}
              </span>
            )}
          </div>
          <br />
          <div className="row">
            <div className="mb-3 col-md-6">
              <label>
                Full Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="fullName"
                className="form-control form-control-bg"
                placeholder="Enter full name"
                defaultValue={logindata?.username || ""}
                readOnly
              />
            </div>

            <div className="mb-3 col-md-6">
              <label>
                Date Of Birth<span className="text-danger">*</span>
              </label>
              <input
                type="date"
                name="dateOfBirth"
                className="form-control form-control-bg"
                placeholder="mm/dd/yyyy"
                ref={dobRef}
                onFocus={() => handleFocus("dob")}
                max={maxDOB}
                required
              />
              {errors.dob && <span className="text-danger">{errors.dob}</span>}
            </div>
          </div>

          <div className="row">
            <div className="mb-3 col-md-6">
              <label>
                Gender<span className="text-danger">*</span>
              </label>
              <select
                name="gender"
                className="form-control form-control-bg"
                ref={genderRef}
                onFocus={() => handleFocus("gender")}
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              {errors.gender && (
                <span className="text-danger">{errors.gender}</span>
              )}
            </div>

            <div className="mb-3 col-md-6">
              <label>
                Father's Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="fatherName"
                className="form-control form-control-bg"
                placeholder="Enter father's name"
                ref={fatherNameRef}
                required
                onFocus={() => handleFocus("fatherName")}
              />
              {errors.fatherName && (
                <span className="text-danger">{errors.fatherName}</span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="mb-3 col-md-6">
              <label>
                Email<span className="text-danger">*</span>
              </label>
              <input
                type="email"
                name="email"
                className="form-control form-control-bg"
                placeholder="Enter email address"
                defaultValue={logindata?.email}
                readOnly
              />
            </div>

            <div className="mb-3 col-md-6">
              <label>
                Contact<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="contact"
                className="form-control form-control-bg"
                placeholder="Enter contact number"
                defaultValue={logindata?.contact}
                required
              />
            </div>
          </div>

          <div className="mb-3">
            <label>
              Address<span className="text-danger">*</span>
            </label>
            <textarea
              name="address"
              className="form-control form-control-bg"
              placeholder="Enter address"
              rows="2"
              ref={addressRef}
              required
              onFocus={() => handleFocus("address")}
            />
            {errors.address && (
              <span className="text-danger">{errors.address}</span>
            )}
          </div>

          <div className="mb-3">
            <label>
              LinkedIn Profile <span className="text-muted">(optional)</span>
            </label>
            <input
              type="url"
              name="linkedin"
              className="form-control form-control-bg"
              placeholder="LinkedIn profile URL"
              ref={linkedin_profile_Ref}
            />
          </div>

          <div className="mb-3">
            <label>
              GitHub Profile <span className="text-muted">(optional)</span>
            </label>
            <input
              type="url"
              name="github"
              className="form-control form-control-bg"
              placeholder="GitHub profile URL"
              ref={github_profile_Ref}
            />
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-module.exports = {
              
            };
             btn-success"
            >
              {loading ? "Saving..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddInfo;
