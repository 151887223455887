import React, { useEffect, useState } from "react";
import "./UseTemp7.css";
import ResumeService, { urls } from "../../../services/ResumeService";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaSquare,
  FaPhone,
  FaEnvelope,
  FaMapMarker,
  FaLinkedin,
  FaGithub,
} from "react-icons/fa";
import html2canvas from "html2canvas";
import jspdf, { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";

const UseTemp7 = () => {
  const logindata = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const candidate = JSON.parse(localStorage.getItem("candidate"));
  const SERVER = process.env.REACT_APP_LIVE_SKILLEDFRESHER_SERVER;



const downloadPDF = () => {
  const input = document.getElementById("wrapper"); // Adjust ID if necessary

  html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    // Scale ratio to fit canvas content within the PDF width
    const scaleRatio = pdfWidth / canvasWidth;
    const scaledHeight = canvasHeight * scaleRatio;

    // Define top and bottom padding in PDF units (e.g., mm)

    const topPadding = 6;
    const bottomPadding = 6;

    // Adjust available page height to account for top and bottom padding
    const adjustedPageHeight = pdfHeight - topPadding - bottomPadding;

    // Break down content into multiple pages if needed
    let yOffset = 0;
    while (yOffset < canvasHeight) {
      const pageCanvas = document.createElement("canvas");
      pageCanvas.width = canvas.width;
      pageCanvas.height = Math.min(
        canvasHeight - yOffset,
        adjustedPageHeight / scaleRatio
      );

      const context = pageCanvas.getContext("2d");
      context.drawImage(
        canvas,
        0,
        yOffset,
        canvas.width,
        pageCanvas.height,
        0,
        0,
        canvas.width,
        pageCanvas.height
      );

      const pageData = pageCanvas.toDataURL("image/png");

      // Add content to the PDF with top padding on each page
      pdf.addImage(
        pageData,
        "PNG",
        0,
        yOffset === 0 ? 0 : topPadding, // No top padding for the first page
        pdfWidth,
        (pageCanvas.height * pdfWidth) / canvas.width
      );

      yOffset += pageCanvas.height;

      if (yOffset < canvasHeight) pdf.addPage(); // Add a new page if more content remains
    }

    pdf.save("profile.pdf");
  });
};


  return (
    <>
      <div className="container">
        {/* <button className="btn  mx-3 db7" onClick={downloadPDF}>
        Download as PDF
      </button> */}
        <div className="button-container7 row">
          <div className="col-12 col-sm-12 col-md-6">
            <button
              className="btn bk7 back-button"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <button className="btn  db7 download-button" onClick={downloadPDF}>
              Download as PDF
            </button>
          </div>
        </div>

        <div className="scroll-container7">
          <div className="profile-container7" id="profile-7">
            <div className="mainbox7 ">
              <div className="sleft">
                <div
                  className="col-md-8 my-4 main7 offset-md-2 col-8 offset"
                  id="wrapper"
                >
                  <div id="header7">
                    <div>
                      <img
                        src={
                          `${SERVER}/upload/user_image/${candidate?.candidates[0]?.image}` ||
                          "/path/to/default-image.png"
                        }
                        alt="Candidate"
                        id="image7"
                      />
                    </div>
                    <div>
                      <h2 id="name" className="name7">
                        {candidate?.name}
                      </h2>
                      <p id="underline7" className="pt7"></p>
                      <h5 id="web7">{candidate?.candidates[0]?.job_title}</h5>
                    </div>
                    <div className="contactdetail7">
                      <div>
                        <FaPhone /> <span>{candidate?.contact}</span>
                      </div>
        <div className="d-flex  flex-sm-row align-items-center">
           <FaEnvelope />
           <span className="text-break ms-2">{candidate?.email}</span>
          </div>

                    </div>
                  </div>
                  <div className="row">
                    {candidate?.candidates[0]?.objective && (
                      <div className="col-md-12 col-sm-12 col-12">
                        <h4 id="exp7">Career Objective</h4>
                        <p className="pt7">
                          {candidate?.candidates[0]?.objective}
                        </p>
                      </div>
                    )}
                  </div>
                  {candidate?.experience?.length > 0 && (
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <h4 id="exp7">Experience</h4>
                        {candidate?.experience?.map((item, index) => (
                          <div key={index} id="experience">
                            <div id="exp17">
                              <FaSquare className="me-1" />
                              <b className="me-1">Designation:</b>
                              <span id="head" className="jobtitle7">{item?.designation}</span>
                            </div>
                            <div id="exp17">
                              <FaSquare className="me-1" />
                              <b className="me-1">Company Name:</b>
                              <span id="head" className="jobtitle7">{item?.company_name}</span>
                            </div>
                            <div id="exp17 ">
                              <FaSquare className="me-1" />
                              <b className="me-1">Description:</b>
                              <span id="head">
                                {item?.description}
                              </span>
                            </div>
                            <div id="exp17">
                              <FaSquare className="me-1" />
                              <b className="me-1">Duration:</b>
                              <span id="head">
                                {item.start_date} to{" "}
                                {item?.end_date || "Present"}{" "}
                              </span>
                              <hr className="hr-4" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {candidate?.project?.length > 0 && (
                    <div className="row mt-3">
                      <div className="col-md-12 col-sm-12 col-12">
                        <h4 id="exp7">Project</h4>
                        {candidate?.project?.map((item, index) => (
                          <div key={index} id="experience">
                            <div id="exp17">
                              <FaSquare className="me-1" />
                              <span id="head">
                                <b className="me-1">Project Title:</b><span className="jobtitle7"> {item?.project_title}
                                </span> </span>
                              <div className="mt-2">
                                <p className="pt7">
                                  <FaSquare className="me-1" />
                                  <b className="bold7 me-1"> Skills Apply: </b>
                                  <span id="head">{item?.skill_apply}</span>
                                </p>
                                <p className="pt7">
                                  <FaSquare className="me-1" />
                                  <b className="bold7 me-1">
                                    Duration in month:
                                  </b>{" "}
                                  <span id="head">
                                    {" "}
                                    {item?.duration_in_month}
                                  </span>
                                </p>
                                <p className="pt7">
                                  <FaSquare className="me-1"/>
                                  <b className="bold7 me-1"> Description:</b>
                                  <span id="head"> {item?.description}</span>
                                </p>
                                <hr className="hr-4" />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {candidate?.educational?.length > 0 && (
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-12">
                        <h4 id="exp7">Education</h4>
                        {candidate?.educational?.map((item, index) => (
                          <div key={index} id="experience">
                            <div id="exp17">
                              <FaSquare className="me-1" />
                              <span id="head">
                                <b className="me-1">Institute/University:</b>
                                {item?.institute_name}/{" "}
                                {item?.board_or_university}
                              </span>
                              <div className="mt-2">
                                <p className="pt7">
                                  <FaSquare className="me-1" />

                                  <b className="bold7 me-1">Percentage/Grade:</b>
                                  {item?.percentage_or_grade}
                                </p>
                                <p className="pt7">
                                  <FaSquare className="me-1" />
                                  <b className="bold7 me-1">Passing Year:</b>
                                  {item?.passing_year}
                                </p>
                                <hr className="hr-4" />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="row ">
                    {candidate?.achievement?.length > 0 && (
                      <div className="col-md-6 col-sm-6 col-6">
                        <h4 id="contact7">Achievements</h4>
                        {candidate?.achievement?.map((item, index) => (
                          <div key={index} className="mb-2 mt-2">
                         
                            <span id="space" className="d-flex">
                            <FaSquare className="me-1 mt-1" />
                              <b className="me-1">Title:</b>
                             <span className="jobtitle7">{item?.title}</span> 
                            </span>
                            <div>
                              <FaSquare className="me-1" />
                              <span id="space">
                                <b className="me-1">Description:</b>
                                 {item?.description}
                              </span>
                            </div>
                            <hr className="hr-4" />
                          </div>
                        ))}
                      </div>
                    )}
                
                        {candidate?.candidates[0]?.interest && (
                      <div className="col-md-6 col-sm-6 col-6">
                        <h4 id="skills7">Hobby</h4>
                        <p className="pt7 mt-1">
                          {candidate?.candidates[0]?.interest}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="row">
                    {candidate?.candidates[0]?.language_known && (
                      <div className="col-md-6 col-sm-6 col-6">
                        <h4 id="contact7">Language</h4>
                        <p className="pt7 mt-1">
                          {candidate?.candidates[0]?.language_known}
                        </p>
                      </div>
                    )}
                      {candidate?.candidates[0]?.skills && (
                      <div className="col-md-6 col-sm-6 col-6">
                        <h4 id="skills7">Skills</h4>
                        <div className="skills7">
                          <div>
                            <p className="pt7">
                              {candidate?.candidates[0]?.skills}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

             
                  <div className="row mb-5">
  <div className="col-md-6 col-sm-6 col-6 contact-7">
    <h4 id="contact7">Contact</h4>
    <div className="mb-2 mt-2">
      <FaPhone className="contact-icon" />
      <span id="space" className="ms-2">
        {candidate?.contact}
      </span>
    </div>
    <div className="mb-2 mt-2">
      <FaEnvelope className="contact-icon" />
      <span id="space" className="ms-2">
        {candidate?.email}
      </span>
    </div>
    <div className="mb-2 mt-2 github-profile-container">
      <FaMapMarker className="contact-icon" />
      <span id="space" className="ms-2 github-profile-text">
        {candidate?.candidates[0]?.address}
      </span>
    </div>
    {candidate?.candidates[0]?.linkedin_profile && (
      <div className="mb-2 mt-2 github-profile-container">
        <FaLinkedin className="contact-icon" />
        <span id="space" className="ms-2 github-profile-text">
          {candidate?.candidates[0]?.linkedin_profile}
        </span>
      </div>
    )}
    {candidate?.candidates[0]?.github_profile && (
      <div className="mb-2 mt-2 github-profile-container">
        <FaGithub className="contact-icon" />
        <span id="space" className="ms-2 github-profile-text">
          {candidate?.candidates[0]?.github_profile}
        </span>
      </div>
    )}
  </div>
  <div className="col-md-6 col-sm-6 col-6">
    <h4 id="contact7">Personal Detail</h4>
    <div className="mb-2 mt-2">
      <b className="me-1">Name:</b> {candidate?.name}
    </div>
    {candidate?.candidates[0]?.father_name && (
      <div className="mb-2 mt-2">
        <b className="me-1">Father's Name:</b>{" "}
        {candidate?.candidates[0]?.father_name}
      </div>
    )}
    {candidate?.candidates[0]?.date_of_birth && (
      <div className="mb-2 mt-2">
        <b className="me-1">DOB:</b>{" "}
        {candidate?.candidates[0]?.date_of_birth}
      </div>
    )}
    {candidate?.candidates[0]?.gender && (
      <div className="mb-2 mt-2">
        <b className="me-1">Gender:</b>{" "}
        {candidate?.candidates[0]?.gender}
      </div>
    )}
    {candidate?.candidates[0]?.marital_status && (
      <div className="mb-2 mt-2">
        <b className="me-1">Marital Status:</b>
        {candidate?.candidates[0]?.marital_status}
      </div>
    )}
  </div>
</div>
  <div className="footer4 mt-4">
                    <p className="footer-text4">
                      Powered By{" "}
                      <a
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-link4"
                      >
                        skillfresher.in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UseTemp7;
