import React, { useEffect, useState } from "react";
import "./UseTemp10.css";
import ResumeService, { urls } from "../../../services/ResumeService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UseTemp10 = () => {
  const logindata = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const candidate = JSON.parse(localStorage.getItem("candidate"));

  const downloadPDF = () => {
    const input = document.getElementById("resume-content10"); // Adjust ID if necessary

    html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Scale ratio to fit canvas content within the PDF width
      const scaleRatio = pdfWidth / canvasWidth;
      const scaledHeight = canvasHeight * scaleRatio;

      const topPadding = 6;
      const bottomPadding = 6;

      // Adjust available page height to account for top and bottom padding
      const adjustedPageHeight = pdfHeight - topPadding - bottomPadding;

      // Break down content into multiple pages if needed
      let yOffset = 0;
      while (yOffset < canvasHeight) {
        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = canvas.width;
        pageCanvas.height = Math.min(
          canvasHeight - yOffset,
          adjustedPageHeight / scaleRatio
        );

        const context = pageCanvas.getContext("2d");
        context.drawImage(
          canvas,
          0,
          yOffset,
          canvas.width,
          pageCanvas.height,
          0,
          0,
          canvas.width,
          pageCanvas.height
        );

        const pageData = pageCanvas.toDataURL("image/png");

        // Add content to the PDF with top padding on each page
        pdf.addImage(
          pageData,
          "PNG",
          0,
          yOffset === 0 ? 0 : topPadding, // No top padding for the first page
          pdfWidth,
          (pageCanvas.height * pdfWidth) / canvas.width
        );

        yOffset += pageCanvas.height;

        if (yOffset < canvasHeight) pdf.addPage(); // Add a new page if more content remains
      }

      pdf.save("profile.pdf");
    });
  };

  return (
    <>
      <div className="container-lg gx-0 tempcon10 my-4">
        <div className="button-container10 row">
          <div className="col-12 col-sm-12 col-md-6">
            <button
              className="btn bk10 back-button"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <button className="btn db10 download-button" onClick={downloadPDF}>
              Download as PDF
            </button>
          </div>
        </div>
        <div className="scroll-container10">
          <div id="resume-content10" className="my-4 mx-4">
            <div className="row container">
              <div className="sleft10 col-lg-6 col-6 col-sm-6">
                <div className="top-section10">
                  <p className="n10">{candidate?.name}</p>
                  <h6 id="web10">{candidate?.candidates[0]?.job_title}</h6>
                </div>

                <div className="bg10">
                  <div className="contact10">
                    <p className="heading10 text-wrap">Contact</p>
                    <div className="pp text-wrap">
                      <p className="p10">
                     
                        <span className="span10 d-flex">
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="icon"
                        />
                          {candidate?.candidates[0]?.address}
                        </span>
                      </p>
                      <p className="p10">
                        <FontAwesomeIcon icon={faPhone} className="icon" />
                        <span className="span10">{candidate?.contact}</span>
                      </p>
                      <p className="p10">
                        <FontAwesomeIcon icon={faEnvelope} className="icon" />
                        <span className="span10">{candidate?.email}</span>
                      </p>
                      {candidate?.candidates[0]?.linkedin_profile && (
                        <p className="p10">
                       
                          <span className="span10 d-flex">
                          <FontAwesomeIcon icon={faLinkedin} className="icon" />
                            {candidate?.candidates[0]?.linkedin_profile}
                          </span>
                        </p>
                      )}
                      {candidate?.candidates[0]?.github_profile && (
                        <p className="p10">

                          
                          <span className="span10 d-flex">
                          <FontAwesomeIcon icon={faGithub} className="icon" />
                            {candidate?.candidates[0]?.github_profile}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="pi">
                    <p className="heading10">Personal Info</p>
                    <div className="pp">
                      {candidate?.candidates[0]?.father_name && (
                        <p className="p10">
                          <b> Father Name -</b>{" "}
                          <span className="span10">
                            {candidate?.candidates[0]?.father_name}
                          </span>
                        </p>
                      )}
                      {candidate?.candidates[0]?.date_of_birth && (
                        <p className="p10">
                          <b> Date of Birth -</b>{" "}
                          <span className="span10">
                            {candidate?.candidates[0]?.date_of_birth}
                          </span>
                        </p>
                      )}
                      {candidate?.candidates[0]?.gender && (
                        <p className="p10">
                          <b> Gender -</b>{" "}
                          <span className="span10">
                            {candidate?.candidates[0]?.gender}
                          </span>
                        </p>
                      )}
                      {candidate?.candidates[0]?.marital_status && (
                        <p className="p10">
                          <b> Marital Status -</b>{" "}
                          <span className="span10">
                            {candidate?.candidates[0]?.marital_status}
                          </span>
                        </p>
                      )}
                      {candidate?.candidates[0]?.nationality && (
                        <p className="p10">
                          <b>Nationality -</b>{" "}
                          <span className="span10">
                            {candidate?.candidates[0]?.nationality}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                  {candidate?.candidates[0]?.skills && (
                    <div className="skills">
                      <p className="heading10">Skills</p>
                      <div className="pp">
                        <ul>
                          <li className="p10">
                            <span className="span10">
                              {candidate?.candidates[0]?.skills}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  {candidate?.candidates[0]?.language_known && (
                    <div className="language">
                      <p className="heading10">Language Known</p>
                      <div className="pp">
                        <p className="p10">
                          <span className="span10">
                            {candidate?.candidates[0]?.language_known}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {candidate?.candidates[0]?.interest && (
                    <div className="interest">
                      <p className="heading10">Interest</p>
                      <div className="pp">
                        <p className="p10">
                          <span className="span10">
                            {candidate?.candidates[0]?.interest}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {candidate?.project?.length > 0 && (
                    <div className="projects">
                      <p className="heading10">Projects</p>
                      <div className="pp">
                        {candidate?.project.map((project, index) => (
                          <div key={index}>
                            <p className="p101">
                              <b className="bold me-1 title10">Project Title:</b>
                              <b className="jobtitle10">{project?.project_title}</b>
                            </p>
                            <p className="p10">
                              <b className="bold me-1">Description:</b>
                              {project?.description}
                            </p>
                            <p className="p10">
                              <b className="bold me-1">Duration in month:</b>
                              {project?.duration_in_month}
                            </p>
                            <p className="p10">
                              <b className="bold me-1">Skills Apply:</b>
                              {project?.skill_apply}
                            </p>
                            <hr className="hr3" />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="sright10 col-lg-6 col-6 col-sm-6">
                {candidate?.candidates[0]?.objective && (
                  <div className="objective">
                    <p className="heading-right10">Objective</p>
                    <p className="pr10">
                      {candidate?.candidates[0]?.objective}
                    </p>
                  </div>
                )}
                {/* Experience Section */}
                {candidate?.experience?.length > 0 && (
                  <div className="experience">
                    <p className="heading-right10">Experience</p>
                    {candidate?.experience?.map((exp, idx) => (
                      <div className="pp" key={idx}>
                        <p className="pr10">
                          <b>
                            {exp?.start_date}
                            {exp?.end_date ? ` - ${exp?.end_date}` : ""}
                          </b>
                        </p>
                        <p className="pr10">
                          <b className="jobtitle10">{exp?.company_name}</b>
                        </p>
                        <p className="pr10 jobtitle10">{exp?.designation}</p>
                        <p className="pr10">{exp?.description}</p>
                        <hr className="hr3" />
                      </div>
                    ))}
                  </div>
                )}
                {/* Education Section */}
                {candidate?.educational?.length > 0 && (
                  <div className="education">
                    <p className="heading-right10">Education</p>

                    <div className="pp">
                      {candidate?.educational?.map((edu, index) => (
                        <div key={index}>
                          <p className="pr10">
                            <b className="me-1">Degree/Program:</b>
                            <span className="upercase ms-1">
                              {edu?.education_name}
                            </span>
                          </p>
                          <p className="pr10">
                            <b className="me-1">Passing Year:</b>
                            <span className="span10">{edu?.passing_year}</span>
                          </p>
                          <p className="pr10">
                            <b className="me-1">Institute/University:</b>
                            <span className="span10">
                              {edu?.institute_name} / {edu?.board_or_university}
                            </span>
                          </p>
                          <p className="pr10">
                            <b className="me-1">Percentage/Grade:</b>
                            <span className="span10">
                              {edu?.percentage_or_grade}
                            </span>
                          </p>
                          <hr className="hr3" />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {/* Achievements Section */}
                {candidate?.achievement?.length > 0 && (
                  <div className="achievements">
                    <p className="heading-right10">Achievements</p>
                    {candidate?.achievement?.map((ach, idx) => (
                      <div className="pp" key={idx}>
                        <p className="pr101">
                          <b className="me-1 title10">Title:</b>
                          <b className="jobtitle10">{ach.title}</b>
                        </p>
                        <p className="pr10">
                          <b className="me-1">Description:</b>
                          {ach.description}
                        </p>
                        <hr className="hr3" />
                      </div>
                    ))}
                  </div>
                )}
                {/* Add sections for experience, education, and achievements using similar structure */}
              </div>
            </div>

            <div className="footer10 mt-5">
              <p className="footer-text10">
                Powered By{" "}
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link10"
                >
                  skillfresher.in
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UseTemp10;
