import axios from "axios";
import { getNetworkStatus } from "../components/NetworkStatus";

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: "http://46.28.44.188:7074/", 
  headers: {
    "Content-Type": "application/json",
  },
});

// Axios request interceptor to check network status before API call
axiosInstance.interceptors.request.use(
  async (config) => {
    const isOnline = getNetworkStatus(); // Get network status from global utility

    if (!isOnline) {
      // If offline, show network error and prevent request from being sent
      // Handle network error (could show a global error message here)
      return Promise.reject(new Error("No internet connection"));
    }

    // Continue with the request if online
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios response interceptor
axiosInstance.interceptors.response.use(
  (response) => response, // Return the response if successful
  (error) => {
    // You can add additional error handling here if needed
    return Promise.reject(error);
  }
);

export default axiosInstance;
