// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-image {
  border: 2px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.file-upload-icon {
  font-size: 1rem;
  transition: transform 0.3s ease-in-out;
}

.file-upload-icon:hover {
  transform: scale(1.2);
}
.translate-middle-info {
  transform: translate(113%, 66%) !important;
}

.profile-image {
  width: 150px;
  height: 150px; 
  border-radius: 50%;
 
  object-fit: cover;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); 
  transition: transform 0.3s, box-shadow 0.3s;
}

.profile-image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
}
.table-resume th, .table-resume td {
  text-align: justify;
}

@media (max-width: 768px) {
  .table-resume th, .table-resume td {
    font-size: 14px;
    word-break: break-word;
    text-align: justify;
  }
  .fileupld{
    width: 100%;
    text-wrap: wrap;
  }
  .mi{
    margin-top: 22px;
  }

}

.disabled-input {
  pointer-events: none;
  opacity: 0.7; 
  cursor: pointer;
}

.nowrapi{
  text-wrap: nowrap !important;
}

`, "",{"version":3,"sources":["webpack://./src/components/Resume/Page/Information.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,0CAA0C;AAC5C;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;;EAElB,iBAAiB;EACjB,yCAAyC;EACzC,2CAA2C;AAC7C;;AAEA;EACE,sBAAsB;EACtB,yCAAyC;AAC3C;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,eAAe;IACf,sBAAsB;IACtB,mBAAmB;EACrB;EACA;IACE,WAAW;IACX,eAAe;EACjB;EACA;IACE,gBAAgB;EAClB;;AAEF;;AAEA;EACE,oBAAoB;EACpB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".profile-image {\r\n  border: 2px solid #ddd;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\r\n}\r\n\r\n.file-upload-icon {\r\n  font-size: 1rem;\r\n  transition: transform 0.3s ease-in-out;\r\n}\r\n\r\n.file-upload-icon:hover {\r\n  transform: scale(1.2);\r\n}\r\n.translate-middle-info {\r\n  transform: translate(113%, 66%) !important;\r\n}\r\n\r\n.profile-image {\r\n  width: 150px;\r\n  height: 150px; \r\n  border-radius: 50%;\r\n \r\n  object-fit: cover;\r\n  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); \r\n  transition: transform 0.3s, box-shadow 0.3s;\r\n}\r\n\r\n.profile-image:hover {\r\n  transform: scale(1.05);\r\n  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);\r\n}\r\n.table-resume th, .table-resume td {\r\n  text-align: justify;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .table-resume th, .table-resume td {\r\n    font-size: 14px;\r\n    word-break: break-word;\r\n    text-align: justify;\r\n  }\r\n  .fileupld{\r\n    width: 100%;\r\n    text-wrap: wrap;\r\n  }\r\n  .mi{\r\n    margin-top: 22px;\r\n  }\r\n\r\n}\r\n\r\n.disabled-input {\r\n  pointer-events: none;\r\n  opacity: 0.7; \r\n  cursor: pointer;\r\n}\r\n\r\n.nowrapi{\r\n  text-wrap: nowrap !important;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
