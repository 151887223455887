const SERVER = process.env.REACT_APP_LIVE_SKILLEDFRESHER_SERVER;

class CoursesService {
  constructor() {
    this.token = localStorage.getItem("token"); 
  }

  getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  getAllCourses() {
    return fetch(`${SERVER}/common/course/list`, {
    });
  }

  getAllCoursesList(token) {
    return fetch(`${SERVER}/api/course/list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // getAllCoursesList() {
  //   return fetch(`${SERVER}/api/course/listCourse`, {
  //     headers: this.getHeaders(),
  //   });
  // }
  getAllCoursesModule(token) {
    return fetch(`${SERVER}/api/CourseModule/list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new CoursesService();
