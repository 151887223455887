import React, { useEffect, useState } from "react";
import "./UseTemp3.css";
import ResumeService, { urls } from "../../../services/ResumeService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UseTemp3 = () => {
  const logindata = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const candidate = JSON.parse(localStorage.getItem("candidate"));

  const downloadPDF = () => {
    const input = document.getElementById("resume3"); // Adjust ID if necessary

    html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Scale ratio to fit canvas content within the PDF width
      const scaleRatio = pdfWidth / canvasWidth;
      const scaledHeight = canvasHeight * scaleRatio;

      // Define top and bottom padding in PDF units (e.g., mm)

      const topPadding = 6;
      const bottomPadding = 6;

      // Adjust available page height to account for top and bottom padding
      const adjustedPageHeight = pdfHeight - topPadding - bottomPadding;

      // Break down content into multiple pages if needed
      let yOffset = 0;
      while (yOffset < canvasHeight) {
        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = canvas.width;
        pageCanvas.height = Math.min(
          canvasHeight - yOffset,
          adjustedPageHeight / scaleRatio
        );

        const context = pageCanvas.getContext("2d");
        context.drawImage(
          canvas,
          0,
          yOffset,
          canvas.width,
          pageCanvas.height,
          0,
          0,
          canvas.width,
          pageCanvas.height
        );

        const pageData = pageCanvas.toDataURL("image/png");

        // Add content to the PDF with top padding on each page
        pdf.addImage(
          pageData,
          "PNG",
          0,
          yOffset === 0 ? 0 : topPadding, // No top padding for the first page
          pdfWidth,
          (pageCanvas.height * pdfWidth) / canvas.width
        );

        yOffset += pageCanvas.height;

        if (yOffset < canvasHeight) pdf.addPage(); // Add a new page if more content remains
      }

      pdf.save("profile.pdf");
    });
  };
  return (
    <>
      <div className="container">
  
        <div className="button-container3 row">
          <div className="col-12 col-sm-12 col-md-6">
            <button
              className="btn bk3 back-button"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <button className="btn db3 download-button" onClick={downloadPDF}>
              Download as PDF
            </button>
          </div>
        </div>
        <div className="scroll-container3">
          <div className="resume3 mt-5" id="resume3">
            <div className="main3 ">
              <div className="box3">
                <div className="row">
                  <p className="name3">{candidate?.name}</p>
                  {candidate?.candidates?.[0]?.job_title && (
                    <h6 className="job-title3">
                      {candidate?.candidates?.[0]?.job_title}
                    </h6>
                  )}
                  <p className="addr mt-3">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="icon ms-1 me-1"
                    />{" "}
                    {candidate?.candidates[0]?.address} |
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="icon  ms-1 me-1"
                    />{" "}
                    {candidate?.contact} |
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="icon ms-1 me-1"
                    />{" "}
                    {candidate?.email}
                    
                |
                    {candidate?.candidates[0]?.linkedin_profile && (
                      <>
                        <span>
                          <FontAwesomeIcon
                            icon={faLinkedin}
                            className="icon ms-1 me-1"
                          />{" "}
                          {candidate?.candidates[0]?.linkedin_profile}
                        </span>
                        |
                      </>
                    )}

                    {candidate?.candidates[0]?.github_profile && (
                      <span>
                        <FontAwesomeIcon
                          icon={faGithub}
                          className="icon ms-1 me-1"
                        />{" "}
                        {candidate?.candidates[0]?.github_profile}
                      </span>
                    )}
               
                  </p>

                </div>
                {candidate?.candidates[0]?.objective && (
                  <Section title="Objective">
                    <p className="pt3">{candidate?.candidates[0]?.objective}</p>
                  </Section>
                )}
                <Section title="More Information">
                  {candidate?.candidates[0]?.father_name && (
                    <p className="pt3">
                      <b>Father Name: </b>
                      {candidate?.candidates[0]?.father_name}
                    </p>
                  )}
                  {candidate?.candidates[0]?.date_of_birth && (
                    <p className="pt3">
                      <b>Date of Birth:</b>{" "}
                      {candidate?.candidates[0]?.date_of_birth}
                    </p>
                  )}
                  {candidate?.candidates[0]?.gender && (
                    <p className="pt3">
                      <b>Gender: </b>
                      {candidate?.candidates[0]?.gender}
                    </p>
                  )}
                  {candidate?.candidates[0]?.marital_status && (
                    <p className="pt3">
                      <b>Marital Status: </b>
                      {candidate?.candidates[0]?.marital_status}
                    </p>
                  )}
                  {candidate?.candidates[0]?.nationality && (
                    <p className="pt3">
                      <b>Nationality: </b>
                      {candidate?.candidates[0]?.nationality}
                    </p>
                  )}
                </Section>

                {candidate?.experience?.length > 0 && (
                  <Section title="Experience">
                    {candidate?.experience?.map((exp, index) => (
                      <div key={index}>
                        <p className="pt3">
                          <span className="bold-title">
                            {exp?.start_date}
                            {exp?.end_date ? ` to ${exp?.end_date}` : ""}(
                            {exp.duration})
                          </span>
                        </p>
                        <p className="pt3 ">
                          <span className="bold-title">{exp.company_name}</span>
                          - {exp.designation}
                        </p>
                        <p className="pt3">{exp.description}</p>
                        <hr className="hr-4" />
                      </div>
                    ))}
                  </Section>
                )}
{candidate?.educational?.length > 0 && (
  <Section title="Education">
    {candidate?.educational?.map((edu, index) => (
      <div key={index}>
        {edu.education_name && (
          <p className="pt3">
            <b>Degree/Program: </b>{" "}
            <span className="bold-title">{edu.education_name}</span>
          </p>
        )}
        {edu.passing_year && (
          <p className="pt3">
            <b>Passing Year: </b> {edu.passing_year}
          </p>
        )}
        {(edu.institute_name || edu.board_or_university) && (
          <p className="pt3">
            <b>Institute/University: </b>
            {edu.institute_name}/{edu.board_or_university}
          </p>
        )}
        {edu.percentage_or_grade && (
          <p className="pt3">
            <b>Percentage/Grade: </b>
            {edu.percentage_or_grade}
          </p>
        )}
        <hr className="hr-4" />
      </div>
    ))}
  </Section>
)}


                {candidate?.project?.length > 0 && (
                  <Section title="Projects">
                    {candidate?.project?.map((project, index) => (
                      <div key={index}>
                        <p className="pt3">
                          <b className="bold me-1">Project Title:</b>
                          <span className="bold-title">
                            {project.project_title}
                          </span>
                        </p>
                        <p className="pt3">
                          <b className="bold me-1">Description:</b>
                          {project.description}
                        </p>
                        <p className="pt3"> 
                          <b className="bold me-1">Skills Apply:</b>
                          {project.skill_apply}
                        </p>
                        {project.duration_in_months && (
                        <p className="pt3">
                          <b className="bold me-1">Duration in month:</b>
                          {project.duration_in_months} Months
                        </p>
                        )}
                        <hr className="hr-4" />
                      </div>
                    ))}
                  </Section>
                )}
                {candidate?.candidates[0]?.skills && (
                  <Section title="Skills">
                    <p className="pt3">
                      <p className="pt3">{candidate?.candidates[0]?.skills}</p>
                    </p>
                  </Section>
                )}
                {candidate?.achievement?.length > 0 && (
                  <Section title="Achievements">
                    {candidate?.achievement?.map((achievement, index) => (
                      <p className="pt3" key={index}>
                        <span className="bold-title">
                          <b className="me-1">Title:</b>
                          {achievement.title}
                        </span>
                        <br />
                        <span>
                          <b className="me-1">Description:</b>
                          {achievement.description}
                        </span>
                        <hr className="hr-4" />
                      </p>
                    ))}
                  </Section>
                )}
              </div>

              <div className="footer3 ">
                <p className="footer-text3">
                  Powered By{" "}
                  <a
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link3"
                  >
                    skillfresher.in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Section = ({ title, children }) => (
  <div className="section">
    <div className="section-header3">{title}</div>
    <div className="section-content3">{children}</div>
  </div>
);

export default UseTemp3;
