
import { createSlice } from "@reduxjs/toolkit";

// Initial state for the profile slice
const initialState = {
  profileImage: "",
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileImage: (state, action) => {
      state.profileImage = action.payload; 
    },
  },
});

export const { setProfileImage } = profileSlice.actions;

export default profileSlice.reducer;
