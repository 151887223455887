import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import ResumeService, { urls } from "../../../services/ResumeService";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ProjectsDetails() {
  const logindata = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const messageRef = useRef(null);
  const projectListRef = useRef(null);
  const [projectId, setProjectId] = useState(undefined);
  const formRef = useRef(null);

  const project_titleRef = useRef();
  const descriptionRef = useRef();
  const skill_applyRef = useRef();
  const duration_in_monthRef = useRef();

  const fetchProjectList = async () => {
    try {
      setLoading(true);
      const fetchUrl = `${urls.Project_List}`;

      const response = await ResumeService.GetApiCallWithToken(
        fetchUrl,
        logindata.token
      );

      if (
        Array.isArray(response?.data?.data) &&
        response?.data?.data.length > 0
      ) {
        setProjectId(response?.data?.data[0]?.project_id);
      } else {
      }

      if (response?.data?.data) {
        const projects = response?.data?.data;

        projects.forEach((project) => {});

        setProjectList(projects);
      } else {
      }
    } catch (error) {
      setMsg("Error fetching project data. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProjectList();
  }, [logindata.token]);

  const handleProjectSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    const projectDetails = {
      person: logindata.userId,
      project_title: project_titleRef.current?.value || "",
      description: descriptionRef.current?.value || "",
      skill_apply: skill_applyRef.current?.value || "",
      duration_in_month: duration_in_monthRef.current?.value || "",
      project_id:
        isEditMode && editIndex !== null
          ? projectList[editIndex]?.project_id
          : undefined,
    };

    if (!projectDetails.project_title.trim())
      errors.project_title = "Project title field is required";
    if (!projectDetails.description.trim())
      errors.description = "Description field is required";
    if (!projectDetails.skill_apply.trim())
      errors.skill_apply = "Skills field is required";

    if (!projectDetails.duration_in_month.trim())
      errors.duration_in_month = "duration_in_month is required";

    if (Object.keys(errors).length === 0) {
      toast.dismiss();
      setLoading(true);
      try {
        let response;
        if (isEditMode && editIndex !== null) {
          const updatedList = projectList.map((project, idx) =>
            idx === editIndex ? projectDetails : project
          );
          setProjectList(updatedList);

          response = await ResumeService.PutApiCall(
            `${urls.Project_Update}/${projectDetails.project_id}`,
            projectDetails,
            logindata.token
          );
          fetchProjectList();
        } else {
          setProjectList([...projectList, projectDetails]);

          response = await ResumeService.PostApiCallWithToken(
            urls.Project_Add,
            projectDetails,
            logindata.token
          );
          setProjectList([...projectList, response.data]);
          fetchProjectList();
        }

        toast.success(
          response?.data?.message || "Project details saved successfully"
        );
        resetForm();

        localStorage.setItem("Project Data", JSON.stringify(projectList));

        setShowForm(false);

        setTimeout(() => {
          setShowForm(true);
        }, 2000);
      } catch (error) {
        toast.error("Error saving project. Please try again.");
      } finally {
        setLoading(false);
        setIsEditMode(false);
        setEditIndex(null);
        setFormErrors({});
        setIsFormSubmitted(true);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleEdit = (index) => {
    fetchProjectList();
    setIsEditMode(true);
    setEditIndex(index);
    const selectedProject = projectList[index];
    project_titleRef.current.value = selectedProject.project_title;
    descriptionRef.current.value = selectedProject.description;
    skill_applyRef.current.value = selectedProject.skill_apply;
    duration_in_monthRef.current.value = selectedProject.duration_in_month;
    selectedProject.project_id = selectedProject.project_id;
  };
  const resetForm = () => {
    setFormErrors({});
    project_titleRef.current.value = "";
    descriptionRef.current.value = "";
    skill_applyRef.current.value = "";
    duration_in_monthRef.current.value = "";
    setIsEditMode(false);
    setEditIndex(null);
  };

  const handleDelete = async (project_id) => {
    if (!project_id) {
      return;
    }

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this project entry?"
    );

    if (isConfirmed) {
      toast.dismiss();
      try {
        setLoading(true);
        const deleteUrl = `${urls.Project_Delete}/${project_id}`;

        const response = await ResumeService.DelApiCall(
          deleteUrl,
          logindata.token
        );

        if (response?.status === 200) {
          const updatedList = projectList.filter(
            (project) => project.project_id !== project_id
          );
          setProjectList(updatedList);
          localStorage.setItem("project Data", JSON.stringify(updatedList));

          toast.success(
            response?.data?.message || "Project entry deleted successfully"
          );
          resetForm();
          fetchProjectList();
        } else {
          toast.error(
            response?.data?.message ||
              "Error deleting project. Please try again."
          );
        }
      } catch (error) {
        const errorMsg =
          error.response?.data?.message || "An error occurred while deleting.";
        setMsg(errorMsg);
      } finally {
        setLoading(false);
        setTimeout(() => setMsg(""), 2000);
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
        className="responsive-toast"
      />
      <div className="container-fluid">
        <div className="row">
          {/* Sidebar */}
          <div className="col-lg-2  col-2 col-sm-2 col-md-2 mb-3">
            <Sidebar />
          </div>

          {/* Main Content */}
          <div className="col-lg-8  col-8 col-sm-8 offset-3 col-md-10">
            <div className="row">
              {/* Table Section */}
              {projectList.length > 0 && (
                <div className="col-lg-5 col-md-5">
                  <div className="border border-success rounded p-4 bg-light shadow">
                    {/* <h2 className="text-center">Saved Project</h2> */}
                    {/* <hr /> */}
                    <div className="table-responsive table-responsive-resume">
                      <table className="table table-striped">
                        <tbody>
                          {projectList.map((project, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <th className="text-left">Project Title</th>
                                <td className="text-left">
                                  {project?.project_title}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">Description</th>
                                <td className="text-left">
                                  {project?.description}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">Skills Applied</th>
                                <td className="text-left">
                                  {project?.skill_apply}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  Duration (in months)
                                </th>
                                <td className="text-left">
                                  {project?.duration_in_month}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <div className="d-flex justify-content-between">
                                    <button
                                      className="btn btn-md  btn-danger  mt-3"
                                      onClick={() =>
                                        handleDelete(project.project_id)
                                      }
                                    >
                                      Delete
                                    </button>
                                    <button
                                      className="btn btn-md  btn-success mt-3"
                                      onClick={() => handleEdit(index)}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </td>
                              </tr>

                              <tr
                                className="spacer-row"
                                ref={
                                  index === projectList.length - 1
                                    ? projectListRef
                                    : null
                                }
                              ></tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {/* Form Section */}
              <div 
              // className="col-lg-6 offset-lg-1 col-md-5 mi" 
              className={`col-lg-${
                projectList.length === 0 ? "6" : "6"
              } col-md-${projectList.length === 0 ? "6" : "5"} offset-lg-${
                projectList.length === 0 ? "3" : "1"
              } mi`}
              ref={formRef}>
                <div className="border border-success rounded p-4 bg-light shadow mb-4">
                  <h3 className="text-center">
                    {isEditMode ? "Update Project" : "Add New Project"}
                  </h3>
                  <hr />

                  <form onSubmit={handleProjectSubmit}>
                    <div className="form-group">
                      <label htmlFor="company Name">
                        Project Title:<span className="text-danger">*</span>
                      </label>
                      <input
                        name="project_title"
                        className="form-control form-control-bg"
                        ref={project_titleRef}
                        maxLength={100}
                        placeholder="ex. - College Management"
                        required
                      />
                      <small className="text-danger">
                        {formErrors.project_title}
                      </small>
                    </div>

                    <div className="form-group">
                      <label htmlFor="designation">
                        Description:<span className="text-danger">*</span>
                      </label>
                      <textarea
                        name="description"
                        className="form-control form-control-bg"
                        ref={descriptionRef}
                        maxLength={1000}
                        placeholder="Describe Your Project"
                        required
                        id="id_description"
                        cols={40}
                        rows={10}
                      />
                      <small className="text-danger">
                        {formErrors.description}
                      </small>
                    </div>

                    <div className="form-group">
                      <label htmlFor="skills_apply">
                        Skills Applied:<span className="text-danger">*</span>
                      </label>
                      <textarea
                        name="skill_apply"
                        className="form-control form-control-bg"
                        ref={skill_applyRef}
                        maxLength={500}
                        placeholder="ex. - HTML, CSS, BOOTstrap, JavaScript"
                        required
                      />
                      <small className="text-danger">
                        {formErrors.skill_apply}
                      </small>
                    </div>

                    <div className="form-group">
                      <label htmlFor="duration">
                        Duration (in months):
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        name="duration_in_month"
                        className="form-control form-control-bg"
                        ref={duration_in_monthRef}
                        required
                      >
                        <option value="">Select duration</option>
                        {[...Array(12).keys()].map((month) => (
                          <option key={month + 1} value={month + 1}>
                            {month + 1}
                          </option>
                        ))}
                      </select>
                      <small className="text-danger">
                        {formErrors.duration_in_month}
                      </small>
                    </div>

                    <button
                      className="btn btn-md  btn-success mt-3"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Saving..." : isEditMode ? "Update" : "Save"}
                    </button>
                    {isEditMode && (
                      <button
                        type="button"
                        className="btn btn-secondary btn-md ms-2 mt-3"
                        onClick={resetForm}
                      >
                        Cancel
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectsDetails;
